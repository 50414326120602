.cx-mb-20 {
  margin-bottom: 20px;
}

.cx-btn-1 {
  min-width: 177px;
  background-color: #fff;
  border: 2px solid #772569;
  color: #772569;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.cx-btn-1:hover,
.cx-btn-1:focus {
  min-width: 177px;
  background-color: #fff;
  border: 2px solid #772569;
  color: #772569;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.cx-btn-2 {
  min-width: 177px;
  background-color: #772569;
  border: 2px solid #772569;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.cx-btn-2:hover,
.cx-btn-2:focus {
  min-width: 177px;
  background-color: #772569;
  border: 2px solid #772569;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.border-top {
  border-top: 1px solid #70707033;
}

.border-bottom {
  border-bottom: 1px solid #70707033;
}

.announcementnew {
  font-family: "Poppins" !important;
}

.announcementnew .main-section {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 20px;
  width: -webkit-fill-available;
  border-radius: 10px;
}

.announcementnew .main-section .announcement-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px;
}

.announcementnew .main-section .announcement-top label {
  font-weight: 500;
  font-size: 18px;
}

.announcementnew .main-section .announcement-top button {
  background: #abc73e;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 21px;
  border: none;
  color: #ffffff;
  line-height: normal !important;
  font-family: "Poppins";
}

.announcementnew .main-section .announcement-top button:focus-visible,
.announcementnew .main-section .announcement-top button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.announcementnew .main-section .announcement-top button img {
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 2px;
}

/* Card Left Border Colors */
.announcementnew .card.sub-1 {
  border-left: 5px solid #ef5934 !important;
}

.announcementnew .card.sub-2 {
  border-left: 5px solid #608aff !important;
}

.announcementnew .card.sub-3 {
  border-left: 5px solid #9967fe !important;
}

.announcementnew .card.sub-4 {
  border-left: 5px solid #53c7a1 !important;
}

.announcementnew .card.sub-5 {
  border-left: 5px solid #f6de44 !important;
}

/* Card Background Colors */
.card-bg-1 {
  background-color: #fff6f1 !important;
}

.card-bg-2 {
  background-color: #e1e7ff !important;
}

.card-bg-3 {
  background-color: #eae0ff !important;
}

.card-bg-4 {
  background-color: #eafdf6 !important;
}

.card-bg-5 {
  background-color: #fffbe1 !important;
}

.announcementnew .card {
  cursor: pointer;
}

.announcementnew .card .card-text-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #939191;
  margin-bottom: 0;
}

.announcementnew .card .card-body {
  padding: 10px 0 10px;
}

.Common_dropDown .dropdown-toggle::after {
  display: none;
}

.Common_dropDown .dropdown-btn {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0;
}

.Common_dropDown .dropdown-menu {
  min-width: 6rem;
}

.Common_dropDown .dropdown-menu.show {
  background: #ffffff;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 10px 10px;
  inset: -18px 14px auto auto !important;
}

.Common_dropDown .dropdown-item {
  font-weight: 400;
  font-size: 12px !important;
}

.Common_dropDown .dropdown-item img {
  margin-right: 5px;
}

.Common_dropDown .dropdown-item:active {
  /* background-color: none !important; */
}

.Common_dropDown .dropdown .dropdown-menu.show .dropdown-item a {
  color: #282739;
  text-decoration: none;
}

.Common_dropDown .dropdown-btn:hover {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.announcementnew .card .card-body h5 {
  margin: 0;
}

.announcementnew .card .card-footer h5 {
  margin-bottom: 10px;
}

.announcementnew .card .card-text-box p a {
  background: linear-gradient(180deg, #7a2a68 0%, #201d4f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
}

.announcementnew .card .card-footer h5 {
  font-weight: 700 !important;
  font-size: 14px !important;
}

.announcementnew .card.sub-1 .card-footer h5 {
  color: #ef5934 !important;
  background-color: #ffd8ce !important;
}

.announcementnew .card.sub-2 .card-footer h5 {
  color: #608aff !important;
  background-color: #d5defe !important;
}

.announcementnew .card.sub-3 .card-footer h5 {
  color: #9967fe !important;
  background-color: #e3d5ff !important;
}

.announcementnew .card.sub-4 .card-footer h5 {
  color: #53c7a1 !important;
  background-color: #c3fae8 !important;
}

.announcementnew .card.sub-5 .card-footer h5 {
  color: #d0bb2f !important;
  background-color: #fff5b2 !important;
}

.viewannouncementnew {
}

.viewannouncementnew .main-section {
  margin: 10px;
}

.viewannouncementnew .announcement-title-box {
}

.viewannouncementnew .announcement-title-box .at-heading {
  font-weight: 500;
  font-size: 32px;
  color: #051b26;
  margin-bottom: 15px;
}

.viewannouncementnew .announcement-title-box .at-subheading {
  font-weight: 500;
  font-size: 26px;
  color: #766c8e;
  margin-bottom: 15px;
}

.viewannouncementnew .announcement-title-box .at-author {
  font-weight: 500;
  font-size: 16px;
  color: #949fa6;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #70707033;
}

.viewannouncementnew .announcement-title-box .at-author span {
  color: #772569;
  margin-left: 5px;
}

.viewannouncementnew .announcement-title-box .dateandtime-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.viewannouncementnew .announcement-title-box .dateandtime-wrapper .date-stamp,
.viewannouncementnew .announcement-title-box .dateandtime-wrapper .time-stamp {
  background: #ffd8ce;
  font-weight: 700;
  font-size: 14px;
  color: #ef5934;
  min-width: 148px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.viewannouncementnew
  .announcement-title-box
  .dateandtime-wrapper
  .time-stamp
  img {
  margin-right: 5px;
}

.viewannouncementnew .announcement-img-box {
  display: flex;
  justify-content: end;
}

.viewannouncementnew .announcement-description-wrapper {
}

.viewannouncementnew .announcement-description-wrapper .ad-heading {
}

.viewannouncementnew .announcement-description-wrapper .ad-heading p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #051b26;
  margin-bottom: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #70707033;
}

.viewannouncementnew .announcement-description-wrapper .ad-text-box {
  padding: 10px 0 20px;
}

.viewannouncementnew .announcement-description-wrapper .ad-text-box p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #939191;
}

.viewannouncementnew .announcement-description-wrapper .img-slider-wrapper {
  /* width: 95%; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-bottom: 20px;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel
  .carousel-controls {
  display: none;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel
  .carousel-indicators {
  display: none;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel
  .carousel-inner {
  /* width: 33.33% !important; */
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel
  .carousel-item {
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel
  .carousel-item
  img {
  margin-right: 20px;
  border-radius: 10px;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-prev-icon,
.carousel-control-next-icon {
  /* background-image: ; */
  background-image: none;
  border-radius: 50%;
  background-color: #ecf0fd;
  height: 40px;
  width: 40px;
  padding: 10px;
  background-size: 20px 20px;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-prev,
.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-next {
  opacity: 1;
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-next
  .carousel-control-next-icon,
.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-prev
  .carousel-control-prev-icon {
  position: relative;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-next
  .carousel-control-next-icon {
  transform: translateX(78px);
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-prev
  .carousel-control-prev-icon {
  transform: translateX(-78px);
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-prev
  .carousel-control-prev-icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-76%, -32%);
  content: url("../images/MyDiary/prev_icon.png");
  /* color: #772569; */
}

.viewannouncementnew
  .announcement-description-wrapper
  .img-slider-wrapper
  .carousel-control-next
  .carousel-control-next-icon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -32%);
  content: url("../images/MyDiary/next_icon.png");
  /* color: #772569; */
}

.viewannouncementnew .announcement-description-wrapper .audio-wrapper {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(129, 129, 129, 0.2);
  border-radius: 10px;
  margin-bottom: 20px;
  /* display: flex; */
}

.viewannouncementnew .announcement-description-wrapper .audio-wrapper audio {
  width: 100%;
}

.viewannouncementnew
  .announcement-description-wrapper
  .audio-wrapper
  audio::-webkit-media-controls-panel {
  background-color: white;
  height: 80px;
}

.viewannouncementnew .announcement-description-wrapper .docs-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.viewannouncementnew .announcement-description-wrapper .docs-wrapper img {
  height: 230px;
  width: 350px;
  object-fit: contain;
  margin-right: 20px;
  border-radius: 10px;
}

.delete-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}

.delete-btn-wrapper .dlt-btn-1 {
  width: 100px;
  height: 43px;
  background-color: #fff;
  font-weight: 500;
  font-size: 18px;
  color: #949fa6;
  border: 2px solid #949fa6;
  border-radius: 8px;
}

.delete-btn-wrapper .dlt-btn-2 {
  width: 100px;
  height: 43px;
  background-color: #ce5353;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  border: 2px solid #ce5353;
  border-radius: 8px;
}

.delete-btn-wrapper .dlt-btn-1 {
  margin-right: 20px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
}

.btn-wrapper .cx-btn-1 {
  margin-right: 20px;
}

.modal-footer {
  border: none;
}

.modal-footer .btn-wrapper .cx-btn-1,
.modal-footer .btn-wrapper .cx-btn-2 {
  min-width: 91px !important;
  border-radius: 5px;
}

.modal-header {
  border: none;
  padding-bottom: 0;
}
.modal-header .modal-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  width: 100%;
  border-bottom: 1px solid #70707033;
}
body.dark-only .modal-header .modal-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #ffffff;
}
.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.modal-body .delete-text-wrapper {
  font-weight: 400;
  font-size: 18px;
  color: #051b26;
}

body.dark-only .modal-body .delete-text-wrapper {
  font-weight: 400;
  font-size: 18px;
  color: #ffffffff;
}

.modal-form-wrapper .form-label {
  font-weight: 600;
  font-size: 14px;
  color: #242934;
}

.modal-form-wrapper .form-label span {
  color: red;
}

.modal-form-wrapper .image-input-wrapper {
  margin-top: 15px;
}

.modal-form-wrapper .image-input-wrapper .upload-btn {
  border: none;
  background: rgba(222, 217, 233, 0.5);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #8a819f;
  width: 91px;
  height: 35px;
}

.modal-form-wrapper .image-input-wrapper .input-title-text {
  font-weight: 600;
  font-size: 14px;
  color: #242934;
  margin-bottom: 10px;
}

.modal-form-wrapper .image-input-wrapper .BorderedLabel {
  width: 100%;
  height: 196px;
  padding: 20px;
  background: rgba(222, 217, 233, 0.5);
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border: 2px dashed rgba(138, 129, 159, 0.5);
  cursor: pointer;
}

.modal-form-wrapper .image-input-wrapper .BorderedLabel .insiderContent {
  text-align: center;
}

.modal-form-wrapper
  .image-input-wrapper
  .BorderedLabel
  .insiderContent
  .detailTxt {
  font-weight: 400;
  font-size: 14px;
  color: #8a819f;
  margin-bottom: 9px;
}

.modal-form-wrapper
  .image-input-wrapper
  .BorderedLabel
  .insiderContent
  .browseBtn {
  border: none;
  background: rgba(138, 129, 159, 0.3);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #8a819f;
}

.modal-form-wrapper .record-audio-wrapper {
}

.modal-form-wrapper
  .record-audio-wrapper
  .accordion-item:last-of-type
  .accordion-button {
  background: #ffffff !important;
  border: 1px solid #e6edef !important;
  border-radius: 10px 10px 0 0 !important;
  margin-top: 20px !important;
  font-weight: 600;
  font-size: 14px;
  color: #242934;
}

.modal-form-wrapper
  .record-audio-wrapper
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  background: #ffffff !important;
  border: 1px solid #e6edef !important;
  border-radius: 10px !important;
  margin-top: 20px !important;
}

.modal-form-wrapper
  .record-audio-wrapper
  .accordion-item:last-of-type
  .accordion-collapse {
  border-radius: 0;
  border: none;
}

.modal-form-wrapper .record-audio-wrapper .accordion-body {
  border: 1px solid #e6edef;
  border-radius: 0 0 10px 10px;
  border-top: none !important;
}

.modal-form-wrapper .record-audio-wrapper .audio-title {
  font-weight: 600;
  font-size: 14px;
  color: #242934;
  margin-bottom: 5px;
}

.modal-form-wrapper .record-audio-wrapper .audio-note {
  font-weight: 400;
  font-size: 12px;
  color: #ce3131;
  margin-bottom: 20px;
}

.modal-form-wrapper .record-audio-wrapper .recording-wrapper {
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .start-recording-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .start-recording-section
  .sr-title {
  font-weight: 600;
  font-size: 14px;
  color: #242934;
  margin-bottom: 5px;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .start-recording-section
  img {
}

.modal-form-wrapper .record-audio-wrapper .recording-wrapper .starting {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .starting
  .start-title {
  font-weight: 400;
  font-size: 14px;
  color: #766c8e;
  margin: 10px 0;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .starting
  .starting-time-stamp {
  background: linear-gradient(180deg, #772569 0%, #732565 100%);
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 7px 27px;
  margin: 10px 0;
  border-radius: 5px;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .starting
  .recording-btn-wrapper {
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .starting
  .recording-btn-wrapper
  .download {
  border: 1px solid #c8bfdf;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #766c8e;
  width: 154px;
  height: 34px;
  border-radius: 10px;
  margin-right: 20px;
}

.modal-form-wrapper
  .record-audio-wrapper
  .recording-wrapper
  .starting
  .recording-btn-wrapper
  .save {
  background: rgba(200, 191, 223, 0.5);
  width: 154px;
  height: 34px;
  font-weight: 400;
  font-size: 14px;
  color: #766c8e;
  border-radius: 10px;
  border: 1px solid rgba(200, 191, 223, 0.5);
}

.emailnew {
  font-family: "Poppins";
}

.emailnew .main-section {
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.emailnew .email-table-wrapper {
  /* margin: 20px; */
  margin-top: 0;
  background-color: #fff;
  height: calc(100vh - 300px);
  border-radius: 0 0 10px 10px;
  width: 100%;
  overflow-x: auto;
}

.emailnew .email-table-wrapper .email-table {
  width: 100%;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

.emailnew .email-table-wrapper .email-table thead {
  background: rgba(200, 191, 223, 0.1);
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
}

.emailnew .email-table-wrapper .email-table thead tr {
}

.emailnew .email-table-wrapper .email-table thead tr th {
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(30, 10, 27, 0.6);
}

.emailnew .email-table-wrapper .email-table tbody tr td {
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #051b26;
}

.part-img-wrapper .pi-heading {
  font-weight: 500;
  font-size: 16px;
  color: #949fa6;
  margin: 0 20px 0 0;
}

.part-img-wrapper {
  display: flex;
}

.part-img-wrapper img {
  z-index: 99;
  margin-left: -7px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.part-img-wrapper img:nth-child(1) {
  margin-left: 0;
}

.emailnew .announcement-title-box .dateandtime-wrapper {
  margin-bottom: 20px;
}

.emailnew .announcement-title-box .part-img-wrapper {
  margin-bottom: 25px;
}

.part-img-wrapper .part-count {
  background: #ffffff;
  border: 0.5px solid rgba(119, 37, 105, 0.5);
  font-weight: 500;
  font-size: 8px;
  color: #954386;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  text-align: center;
  padding: 5px;
  margin-left: -7px;
  z-index: 999;
  margin-top: 3px;
}

/* Chat Section Styling Start */

.chat {
}

.chat .chat-wrapper {
}

.chat .chat-wrapper .chat-list-section {
  height: calc(100vh - 218px);
  padding: 10px;
  background: #edf3fe;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}

.chat .chat-wrapper .chat-list-section .search-wrapper {
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6edef;
}

.chat .chat-wrapper .chat-list-section .search-wrapper .form-control {
  background: #ffffff;
  border: 1px solid #e6edef;
  border-radius: 10px;
  position: relative;
}

.chat
  .chat-wrapper
  .chat-list-section
  .search-wrapper
  .form-control::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(30, 10, 27, 0.3) !important;
}

.chat .chat-wrapper .chat-list-section .search-wrapper img {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  right: 10px;
  height: 16px;
  width: 18px;
}

.chat .chat-wrapper .chat-list-section p.chat-heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(17, 17, 17, 0.5);
  margin-bottom: 10px;
}

.chat .chat-wrapper .chat-list-section .nav-pills {
  /* height: calc(100vh - 200px);
    overflow-y: scroll; */
}

.chat .chat-wrapper .chat-list-section .nav-pills .nav-link.active {
  background: rgba(200, 191, 223, 0.5);
  border-radius: 10px;
  color: rgba(30, 10, 27, 0.6);
  padding: 10px;
}

.chat .chat-wrapper .chat-list-section .nav-pills .nav-link {
  color: rgba(30, 10, 27, 0.6);
  padding: 10px;
  border-radius: 10px;
}

.chat .chat-wrapper .chat-list-section .nav-pills .nav-link .chat-item-img-box {
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-img-box
  img {
  position: relative;
  border: 2px solid #c8bfdf;
  border-radius: 50%;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-img-box
  span {
  position: absolute;
  bottom: 0;
  left: 25px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 7;
  border: 1px solid #ffffff;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-img-box
  span.status-green {
  background: #1cd63a;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-img-box
  span.status-yellow {
  background: #f9bc35;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-img-box
  span.status-red {
  background: #ff0000;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-text-box {
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-text-box
  .ci-main-text {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 18px;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-text-box
  .ci-sub-text {
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 0;
}

.chat
  .chat-wrapper
  .chat-list-section
  .nav-pills
  .nav-link
  .chat-item-count-box {
  font-weight: 400;
  font-size: 10px;
  color: rgba(30, 10, 27, 0.6);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.chat .chat-wrapper .chat-detail-section {
  height: calc(100vh - 218px);
  padding: 20px;
  background: #edf3fe;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}

.chat .chat-wrapper .chat-detail-section .chat-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #e6edef;
}

.chat .chat-wrapper .chat-detail-section .chat-header .chat-img-box {
  position: relative;
}

.chat .chat-wrapper .chat-detail-section .chat-header .chat-img-box img {
  position: relative;
  border: 2px solid #c8bfdf;
  border-radius: 50%;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.chat .chat-wrapper .chat-detail-section .chat-header .chat-img-box span {
  position: absolute;
  bottom: 0;
  left: 25px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 999;
  border: 1px solid #ffffff;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-header
  .chat-img-box
  span.status-green {
  background: #1cd63a;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-header
  .chat-img-box
  span.status-yellow {
  background: #f9bc35;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-header
  .chat-img-box
  span.status-red {
  background: #ff0000;
}

.chat .chat-wrapper .chat-detail-section .chat-user-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.chat .chat-wrapper .chat-detail-section .chat-header p.user-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: rgba(30, 10, 27, 0.6);
  margin-bottom: 0;
  margin-top: 7px;
}

.chat .chat-wrapper .chat-detail-section .chat-header .chat-dropdown-wrap {
  display: flex;
  justify-content: end;
}

.chat .chat-wrapper .chat-detail-section .chat-main-content {
  height: calc(100vh - 380px);
  padding: 26px 0;
  /* -ms-overflow-style: none !important;
    scrollbar-width: none !important; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-main-content::-webkit-scrollbar {
  display: none !important;
}

.chat .chat-wrapper .chat-detail-section .chat-main-content .sender {
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box {
  position: relative;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box
  img {
  position: relative;
  border: 2px solid #c8bfdf;
  border-radius: 50%;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box
  span {
  position: absolute;
  bottom: 0;
  left: 27px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 999;
  border: 1px solid #ffffff;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box
  span.status-green {
  background: #1cd63a;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box
  span.status-yellow {
  background: #f9bc35;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-img-box
  span.status-red {
  background: #ff0000;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-chat {
  background: rgba(200, 191, 223, 0.5);
  border-radius: 10px 10px 10px 0px;
  padding: 14px 23px;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-chat
  p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* text-transform: uppercase; */
  color: rgba(30, 10, 27, 0.6);
  margin-bottom: 0;
  position: relative;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sender-chat:nth-child(2) {
  background: rgba(200, 191, 223, 0.5);
  border-radius: 0px 10px 10px 10px;
  margin-top: 15px;
  width: max-content;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .sender
  .sc-time-stamp {
  position: absolute;
  bottom: -20%;
  left: 20%;
  text-transform: uppercase;
  color: rgba(17, 17, 17, 0.5);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: right;
}

.chat .chat-wrapper .chat-detail-section .chat-main-content .reciever {
  margin: 40px 0;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-chat
  p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: rgba(30, 10, 27, 0.6);
  margin-bottom: 0;
  position: relative;
  background: #ffffff;
  border-radius: 10px 10px 0px 10px;
  border: 1px solid #e6edef;
  padding: 14px 23px;
  width: max-content;
  /* margin-right: 1.5rem; */
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .rc-time-stamp {
  position: absolute;
  bottom: -50%;
  right: 2%;
  text-transform: uppercase;
  color: rgba(17, 17, 17, 0.5);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: right;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box {
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box
  img {
  position: relative;
  border: 2px solid #c8bfdf;
  border-radius: 50%;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box
  span {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 999;
  border: 1px solid #ffffff;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box
  span.status-green {
  background: #1cd63a;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box
  span.status-yellow {
  background: #f9bc35;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-main-content
  .reciever
  .reciever-img-box
  span.status-red {
  background: #ff0000;
}

.chat .chat-wrapper .chat-detail-section .chat-input-wrapper {
  position: relative;
  padding-top: 15px;
  border-top: 1px solid #e6edef;
  margin-top: 10px;
}

.chat .chat-wrapper .chat-detail-section .chat-input-wrapper .form-control {
  border: none;
  background: transparent;
  padding-right: 60px;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-input-wrapper
  .form-control::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #949fa6;
}

.chat
  .chat-wrapper
  .chat-detail-section
  .chat-input-wrapper
  .form-control:focus {
  box-shadow: none;
}

.chat .chat-wrapper .chat-detail-section .chat-input-wrapper .send-btn img {
}

.chat .chat-wrapper .chat-detail-section .chat-input-wrapper .send-btn {
  background: linear-gradient(180deg, #b23a9c 0%, #79266a 100%);
  width: 38px;
  height: 38px;
  position: absolute;
  right: 10px;
  top: 30%;
  /* transform: translateY(-10%); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Chat Section Styling End */
