body.dark-only {
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
  scrollbar-width: thin !important;
  scrollbar-color: #1f2939 #111727 !important;
}

body.dark-only::-webkit-scrollbar {
  width: 8px !important;
}

body.dark-only::-webkit-scrollbar-track {
  background: #111727 !important;
}

body.dark-only::-webkit-scrollbar-thumb {
  background-color: #1f2939 !important;
  border-radius: 6px !important;
  border: 3px solid #111727 !important;
}

body.dark-only .blog-box h6 {
  color: #fff;
}

body.dark-only .blog-box .blog-details-second .detail-footer {
  border-top: 1px solid #202938;
}

body.dark-only
  .blog-box
  .blog-details-second
  .detail-footer
  ul.sociyal-list
  li:nth-child(n + 2) {
  border-left: 1px solid #202938;
}

body.dark-only a.cke_button {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

body.dark-only .cke_combo_text {
  color: #fff;
}

body.dark-only .job-search .job-description {
  border-top: 1px solid #202938;
}

body.dark-only .job-sidebar .job-left-aside {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .job-search .job-description .theme-form {
  border-bottom: 1px solid #202938;
}

body.dark-only .categories ul li:hover {
  background-color: #202938;
}

body.dark-only .blog-list .blog-details h6 {
  color: #fff;
}

body.dark-only .default-according .card-header {
  background-color: #111727 !important;
}

body.dark-only .login-form {
  background-color: #202938;
}

body.dark-only .login-form .login-social-title h5 {
  background-color: #202938;
}

body.dark-only .calendar-basic .tui-full-calendar-month-dayname span {
  color: #fff !important;
}

body.dark-only
  .tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time
  .tui-full-calendar-weekday-schedule-title,
body.dark-only
  .tui-full-calendar-weekday-grid-line
  .tui-full-calendar-weekday-grid-date {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-only
  .tui-full-calendar-dropdown-menu-item
  .tui-full-calendar-content {
  color: #000;
}

body.dark-only .tui-full-calendar-weekday-border {
  border-top: 1px solid #98a6ad !important;
}

body.dark-only .tui-full-calendar-month-dayname {
  border-top-color: #98a6ad !important;
}

body.dark-only .tui-full-calendar-layout {
  background-color: #202938 !important;
}

body.dark-only .calendar-basic #menu-navi {
  border-bottom: 1px solid #202938;
}

body.dark-only .social-app-profile .card .socialprofile span .badge {
  background-color: #202938;
  color: #fff;
}

body.dark-only .social-list .media .media-body span {
  color: inherit;
}

body.dark-only .edit-profile .profile-title {
  border-bottom: 1px solid #202938;
}

body.dark-only .search-list .nav-item .nav-link {
  color: #fff;
  opacity: 1;
}

body.dark-only .search-list .nav-item .nav-link.active {
  text-shadow: 0px 0px #fff;
}

body.dark-only .datepicker--pointer {
  background: #111727;
  border-top: 1px solid #202938;
  border-right: 1px solid #202938;
}

body.dark-only .form-label {
  color: inherit;
}

body.dark-only .txt-success {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .project-box .details div {
  opacity: 1;
}

body.dark-only .project-box p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .checkout label {
  color: inherit;
}

body.dark-only .prooduct-details-box .product-name h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .product-box .product-img .product-hover ul li a {
  color: #fff;
}

body.dark-only .border-tab.nav-left .nav-link {
  color: #fff;
}

body.dark-only .order-history table .qty-box {
  background-color: #111727;
}

body.dark-only .product-box {
  background-color: #111727;
}

body.dark-only .product-box .product-details h4 {
  color: #efefef;
}

body.dark-only .flot-chart-placeholder .legend table {
  color: #fff !important;
}

body.dark-only .compact-wrapper tbody {
  background-color: #202938;
}

body.dark-only .dashboard-default-sec .card .card-header p,
body.dark-only .dashboard-2-main .card .card-header p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .dashboard-default-sec .card .card-header ul.week-date li,
body.dark-only .dashboard-2-main .card .card-header ul.week-date li {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .dashboard-default-sec
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2),
body.dark-only
  .dashboard-2-main
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .dashboard-default-sec
  .card.income-card
  .card-body
  .knob-block
  input,
body.dark-only
  .dashboard-2-main
  .card.income-card
  .card-body
  .knob-block
  input {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .dashboard-default-sec .latest-update-sec table tbody tr td img,
body.dark-only .dashboard-2-main .latest-update-sec table tbody tr td img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

body.dark-only .dashboard-default-sec span,
body.dark-only .dashboard-default-sec p,
body.dark-only .dashboard-default-sec h5,
body.dark-only .dashboard-default-sec h4,
body.dark-only .dashboard-default-sec h5,
body.dark-only .dashboard-default-sec h6,
body.dark-only .dashboard-default-sec th,
body.dark-only .dashboard-default-sec h3,
body.dark-only .dashboard-default-sec a,
body.dark-only .dashboard-2-main span,
body.dark-only .dashboard-2-main p,
body.dark-only .dashboard-2-main h5,
body.dark-only .dashboard-2-main h4,
body.dark-only .dashboard-2-main h5,
body.dark-only .dashboard-2-main h6,
body.dark-only .dashboard-2-main th,
body.dark-only .dashboard-2-main h3,
body.dark-only .dashboard-2-main a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .dashboard-default-sec .recent-order-sec table tr td p,
body.dark-only .dashboard-2-main .recent-order-sec table tr td p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .setting-list {
  background-color: #111727;
}

body.dark-only .latest-update-sec .media img,
body.dark-only .latest-update-sec .media svg {
  fill: #98a6ad;
}

body.dark-only
  .dashboard-default-sec
  .card
  .card-header
  ul.week-date
  li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .top-dealer-sec .card .top-dealerbox:hover {
  background-color: #202938;
}

body.dark-only .activity-sec .activity-timeline .media .activity-line {
  background-color: #374558;
}

body.dark-only .activity-sec .activity-timeline .media .media-body p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec
  p {
  color: rgba(255, 255, 255, 0.7);
  background-color: #111727;
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  .tomorrow-sec:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .activity-dot-primary {
  background-color: #111727;
}

body.dark-only .user-profile .profile-header .userpro-box {
  background-color: #111727;
}

body.dark-only .user-profile .profile-header .userpro-box h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-header .userpro-box .icon-wrapper {
  background-color: #202938;
}

body.dark-only .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .follow ul.follow-list {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .user-profile .follow ul.follow-list li:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .user-profile .post-about ul li h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-header .media h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-body .post-react h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .user-profile .profile-post .post-body .post-react p {
  color: #98a6ad;
}

body.dark-only .social-tab .input-group .form-control {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .cal-date-widget .datepicker {
  background-color: rgba(36, 105, 92, 0.2);
  border: none;
}

body.dark-only .cal-date-widget .datepicker .datepicker--cell-day {
  color: #fff;
}

body.dark-only .datepicker--day-name {
  color: #24695c;
}

body.dark-only .product-social li a {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:before,
body.dark-only .page-wrapper .page-body-wrapper .page-body .widget-joins:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after {
  background-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-main-title
  > div
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg,
body.dark-only
  .compact-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .nav-link
  svg {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .page-wrapper .product-page-main thead,
body.dark-only .page-wrapper .product-page-main tbody,
body.dark-only .page-wrapper .product-page-main tfoot,
body.dark-only .page-wrapper .product-page-main tr,
body.dark-only .page-wrapper .product-page-main td,
body.dark-only .page-wrapper .product-page-main th,
body.dark-only .compact-wrapper .product-page-main thead,
body.dark-only .compact-wrapper .product-page-main tbody,
body.dark-only .compact-wrapper .product-page-main tfoot,
body.dark-only .compact-wrapper .product-page-main tr,
body.dark-only .compact-wrapper .product-page-main td,
body.dark-only .compact-wrapper .product-page-main th {
  border-color: transparent;
  background-color: transparent;
}

body.dark-only .page-wrapper .product-page-main p,
body.dark-only .compact-wrapper .product-page-main p {
  color: #98a6ad;
}

body.dark-only .page-wrapper thead,
body.dark-only .page-wrapper tbody,
body.dark-only .page-wrapper tfoot,
body.dark-only .page-wrapper tr,
body.dark-only .page-wrapper td,
body.dark-only .page-wrapper th,
body.dark-only .compact-wrapper thead,
body.dark-only .compact-wrapper tbody,
body.dark-only .compact-wrapper tfoot,
body.dark-only .compact-wrapper tr,
body.dark-only .compact-wrapper td,
body.dark-only .compact-wrapper th {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .testimonial i {
  color: #24695c !important;
}

body.dark-only .blockquote {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .datepicker,
body.dark-only .daterangepicker {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker:after {
  border-bottom-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker .input-mini.active {
  border: 1px solid #202938;
}

body.dark-only .daterangepicker .calendar-table {
  background-color: #111727;
  border: none;
}

body.dark-only .daterangepicker.dropdown-menu {
  background-color: #111727;
}

body.dark-only .datepicker--nav {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .datepicker--cell-day {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .datepicker--cell-day.-other-month-,
body.dark-only .datepicker--cell-day .-other-decade-,
body.dark-only .datepicker--cell-year.-other-month-,
body.dark-only .datepicker--cell-year .-other-decade- {
  color: #98a6ad;
}

body.dark-only .date-range-picker .daterange-card {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .daterangepicker td.off,
body.dark-only .daterangepicker td.in-range,
body.dark-only .daterangepicker td.start-date,
body.dark-only .daterangepicker td.end-date {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .daterangepicker .input-mini {
  border-color: rgba(36, 105, 92, 0.2) !important;
  background-color: #111727;
}

body.dark-only .daterangepicker.ltr .calendar-time select {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .ranges li {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .f1 .f1-steps .f1-progress {
  background-color: #202938;
}

body.dark-only .f1 .f1-steps .f1-step .f1-step-icon {
  background-color: #202938;
}

body.dark-only .f1 .f1-steps .f1-step.active .f1-step-icon {
  background-color: #24695c;
}

body.dark-only .b-b-info {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .main-navbar ul.nav-pills {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .main-navbar ul.nav-pills .nav-item:last-child {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .main-navbar ul.nav-pills .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .messanger-chat .chat .message-main p {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only .project-list .border-tab {
  border: none !important;
}

body.dark-only .project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: none;
}

body.dark-only .project-box {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .form-control-plaintext {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-content .form-control-plaintext:focus {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-content .form-inline {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .files .file-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-content .files .file-box .file-top {
  background-color: #202938;
  border-color: #202938;
}

body.dark-only .file-content .folder .folder-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-manager > h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-sidebar .pricing-plan {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .file-sidebar .pricing-plan h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .file-sidebar .pricing-plan p {
  color: #98a6ad;
}

body.dark-only .kanban-board {
  background-color: #202938;
}

body.dark-only .kanban-item .kanban-box {
  border-color: rgba(36, 105, 92, 0.2);
  background-color: #202938;
}

body.dark-only .kanban-container footer {
  border: none !important;
}

body.dark-only .apexcharts-gridline {
  stroke: rgba(36, 105, 92, 0.2);
}

body.dark-only .apexcharts-tooltip-title,
body.dark-only .apexcharts-tooltip-series-group,
body.dark-only .apexcharts-tooltip.light,
body.dark-only .apexcharts-menu-item {
  background-color: #202938 !important;
}

body.dark-only
  .activity-sec
  .activity-timeline.update-line
  .media
  .media-body
  h5 {
  color: rgba(255, 255, 255, 0.8);
}

body.dark-only .page-main-header .main-header-right {
  -webkit-box-shadow: 0 0 18px 3px #111727;
  box-shadow: 0 0 18px 3px #111727;
}

body.dark-only
  .page-main-header
  .main-header-right
  .nav-right.right-menu
  ul
  li:nth-child(2) {
  border-left: 1px solid #202938;
  border-right: 1px solid #202938;
}

body.dark-only
  .page-main-header
  .main-header-right
  .nav-right
  .bookmark-dropdown
  ul
  li:hover {
  background-color: #202938;
}

body.dark-only .page-main-header .main-header-right .main-header-left {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
  border-color: rgb(110 36 103);
}

body.dark-only
  .page-main-header
  .main-header-right
  .main-header-left
  .logo-wrapper {
  display: none;
}

body.dark-only
  .page-main-header
  .main-header-right
  .main-header-left
  .dark-logo-wrapper {
  display: block;
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper .page-body-wrapper {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  + li {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .left-arrow,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper.horizontal-menu
  header.main-nav
  .main-navbar
  .right-arrow {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar {
  -webkit-box-shadow: 0 0 11px #111727;
  box-shadow: 0 0 11px #111727;
}

body.dark-only .alert-primary {
  background-color: rgba(36, 105, 92, 0.8) !important;
  border-color: rgba(36, 105, 92, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-primary .progress {
  height: 5px;
  background-color: #236559;
  border-radius: 0;
}

body.dark-only .alert-primary .progress-bar {
  background-color: #aae2d7;
}

body.dark-only .alert-primary.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-secondary {
  background-color: rgba(186, 137, 93, 0.8) !important;
  border-color: rgba(186, 137, 93, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-secondary .progress {
  height: 5px;
  background-color: #b88659;
  border-radius: 0;
}

body.dark-only .alert-secondary .progress-bar {
  background-color: white;
}

body.dark-only .alert-secondary.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-success {
  background-color: rgba(27, 76, 67, 0.8) !important;
  border-color: rgba(27, 76, 67, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-success .progress {
  height: 5px;
  background-color: #1a4840;
  border-radius: 0;
}

body.dark-only .alert-success .progress-bar {
  background-color: #8fd7ca;
}

body.dark-only .alert-success.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-danger {
  background-color: rgba(210, 45, 61, 0.8) !important;
  border-color: rgba(210, 45, 61, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-danger .progress {
  height: 5px;
  background-color: #ce2c3c;
  border-radius: 0;
}

body.dark-only .alert-danger .progress-bar {
  background-color: white;
}

body.dark-only .alert-danger.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-warning {
  background-color: rgba(226, 198, 54, 0.8) !important;
  border-color: rgba(226, 198, 54, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-warning .progress {
  height: 5px;
  background-color: #e1c532;
  border-radius: 0;
}

body.dark-only .alert-warning .progress-bar {
  background-color: white;
}

body.dark-only .alert-warning.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-info {
  background-color: rgba(113, 113, 113, 0.8) !important;
  border-color: rgba(113, 113, 113, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-info .progress {
  height: 5px;
  background-color: #6e6e6e;
  border-radius: 0;
}

body.dark-only .alert-info .progress-bar {
  background-color: #f1f1f1;
}

body.dark-only .alert-info.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-light {
  background-color: rgba(230, 237, 239, 0.8) !important;
  border-color: rgba(230, 237, 239, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-light .progress {
  height: 5px;
  background-color: #e3ebed;
  border-radius: 0;
}

body.dark-only .alert-light .progress-bar {
  background-color: white;
}

body.dark-only .alert-light.inverse {
  background-color: transparent !important;
}

body.dark-only .alert-dark {
  background-color: rgba(44, 50, 63, 0.8) !important;
  border-color: rgba(44, 50, 63, 0.9) !important;
  color: #fff;
}

body.dark-only .alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}

body.dark-only .alert-dark .progress-bar {
  background-color: #a8b0c2;
}

body.dark-only .alert-dark.inverse {
  background-color: transparent !important;
}

body.dark-only .default-according .card .card-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .options > div {
  border: 1px solid #202938;
}

body.dark-only .pricing-simple {
  -webkit-box-shadow: 1px 1px 2px 1px #202938 !important;
  box-shadow: 1px 1px 2px 1px #202938 !important;
}

body.dark-only .pricing-block {
  -webkit-box-shadow: 0 0 10px 5px rgba(36, 105, 92, 0.05) !important;
  box-shadow: 0 0 10px 5px rgba(36, 105, 92, 0.05) !important;
}

body.dark-only .search-page ul.search-info li + li {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .search-form input {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #202938;
}

body.dark-only .form-control {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .input-group-text {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .input-group-text {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .status-circle {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .browse .browse-articles h6 {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .job-search
  .job-description
  .theme-form
  .row
  div[class^="col-"]
  .input-group
  .datepicker-here {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .calendar-wrap .fc-unthemed td,
body.dark-only .calendar-wrap .fc-unthemed th {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .mega-inline .card,
body.dark-only .mega-horizontal .card {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .setting-dot .setting-bg {
  background-color: #ba895d;
}

body.dark-only .bookmark.pull-right {
  border: none;
}

body.dark-only .card .card-header h5 {
  background: unset;
  -webkit-text-fill-color: #fff;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-success {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-primary {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-warning {
  background: #202938;
}

body.dark-only
  .timeline-circle
  .media
  .timeline-background
  .timeline-dot-secondary {
  background: #202938;
}

body.dark-only .timeline-circle .media .timeline-background .timeline-dot-info {
  background: #202938;
}

body.dark-only .timeline-circle .media p,
body.dark-only .timeline-circle .media span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .apex-chart-container
  .apexcharts-legend
  .apexcharts-legend-series
  span {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .apexcharts-canvas svg .apexcharts-title-text {
  fill: #fff;
}

body.dark-only .apexcharts-canvas svg .apexcharts-subtitle-text {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-yaxis
  .apexcharts-yaxis-texts-g
  .apexcharts-yaxis-label {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-xaxis
  .apexcharts-xaxis-texts-g
  .apexcharts-xaxis-label {
  fill: #fff;
}

body.dark-only .apexcharts-canvas svg .apexcharts-legend-series span {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-label {
  fill: #fff;
}

body.dark-only
  .apexcharts-canvas
  svg
  .apexcharts-datalabels-group
  .apexcharts-datalabel-value {
  fill: #fff;
}

body.dark-only .Typeahead-menu {
  background-color: #202938;
}

body.dark-only .ecommerce-widget {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .contact-form .theme-form {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .bookmark ul li {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .bookmark ul li .search-form .form-control-search input {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bookmark ul li .search-form .form-control-search:before {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .cart .qty-box .input-group .btn {
  background-color: #2e3648 !important;
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .checkout .checkout-details {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  padding: 40px;
}

body.dark-only .checkout .checkout-details .animate-chk .radio_animated:after {
  border: 2px solid #111727;
}

body.dark-only .order-box .title-box {
  color: #bfc2c6;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .qty {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .qty li {
  color: #bfc2c6;
}

body.dark-only .order-box .qty li span {
  color: #bfc2c6;
}

body.dark-only .order-box .sub-total li {
  color: #bfc2c6;
}

body.dark-only .order-box .sub-total .shipping-class {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .order-box .total li {
  color: #bfc2c6;
}

body.dark-only .shopping-checkout-option .checkbox_animated:after {
  border: 2px solid #111727;
}

body.dark-only .animate-chk .radio_animated:after {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(255 255 255 / 10%);
}

body.dark-only .product-box .product-img .product-hover ul li .btn {
  color: #98a6ad;
}

body.dark-only .product-box .product-img .product-hover ul li:hover .btn {
  color: #fff;
}

body.dark-only .radio_animated:after,
body.dark-only .checkbox_animated:after {
  background: #202938;
  border: 2px solid #202938;
}

body.dark-only .slider-product {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .square-product-setting .icon-grid {
  background-color: #111727;
}

body.dark-only .square-product-setting .icon-grid svg {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .active-order-table table thead tr th,
body.dark-only .market-table table thead tr th {
  border-top: none !important;
}

body.dark-only .active-order-table table tbody tr td,
body.dark-only .market-table table tbody tr td {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .active-order-table table tbody tr:last-child td,
body.dark-only .market-table table tbody tr:last-child td {
  border-bottom: none;
}

body.dark-only .pricing-card-design-2 .pricing-block .pricing-inner ul li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .flot-chart-container .legend .table tbody {
  background-color: #111727;
  color: #fff;
}

body.dark-only .flot-chart-container .legend .table tbody .legendLabel {
  padding-left: 5px;
}

body.dark-only .google-visualization-tooltip text {
  fill: rgba(0, 0, 0, 0.7) !important;
}

body.dark-only .nav-dark .nav-link,
body.dark-only .nav-pills.nav-dark .nav-link {
  color: #fff;
}

body.dark-only .loader-wrapper {
  background-color: #202938;
}

body.dark-only .loader-wrapper .loader {
  background-color: #202938 !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav {
  background-color: #111727;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li:hover
  a {
  color: #fff !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a {
  color: rgba(255, 255, 255, 0.8) !important;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .nav-menu
  .dropdown
  ul.nav-submenu
  li
  a.active {
  color: #fff !important;
  text-shadow: 0px 0px #fff;
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  ul
  li
  + li:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .sidebar-user
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-title
  h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  > li
  .mega-menu-container
  .mega-box
  .link-section
  .submenu-content.opensubmegamenu
  ul
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  .according-menu
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.compact-wrapper .page-main-header {
  border-bottom: 1px solid rgb(107 35 101 / 0%);
}

body.dark-only
  .page-wrapper.compact-sidebar.compact-wrapper
  .page-body-wrapper
  header.main-nav
  .main-navbar
  .nav-menu
  .dropdown
  .menu-title.active
  ~ .menu-content {
  background-color: #111727;
}

body.dark-only .btn-primary-light {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .popover {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .popover-header {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .popover-body {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bs-popover-end > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow:after {
  border-right-color: #202938;
}

body.dark-only .bs-popover-bottom > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow:after {
  border-right-color: #202938;
}

body.dark-only .bs-popover-top > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow:after {
  border-top-color: #202938;
}

body.dark-only .bs-popover-start > .popover-arrow:after,
body.dark-only
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow:after {
  border-left-color: #202938;
}

body.dark-only .default-according .card .card-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .border-tab.nav-tabs .nav-item .nav-link {
  border-bottom: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .border-tab.nav-tabs .nav-item .nav-link.active,
body.dark-only .border-tab.nav-tabs .nav-item .nav-link.show,
body.dark-only .border-tab.nav-tabs .nav-item .nav-link:focus {
  border-color: #24695c;
}

body.dark-only .border-tab.nav-left.nav-success .nav-link.active {
  border-left: #717171;
}

body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left: #717171;
}

body.dark-only .border-tab.nav-success .nav-item .nav-link.active,
body.dark-only .border-tab.nav-success .nav-item .nav-link.show,
body.dark-only .border-tab.nav-success .nav-item .nav-link:focus,
body.dark-only .border-tab.nav-success .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .form-control,
body.dark-only .form-select {
  background-color: rgba(36, 105, 92, 0.2);
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .was-validated .form-check-input:invalid,
body.dark-only .form-check-input .form-check-input:invalid {
  background-color: #111727;
}

body.dark-only .was-validated .is-invalid,
body.dark-only .form-check-input .is-invalid {
  background-color: #111727;
}

body.dark-only .u-pearl.error .u-pearl-icon,
body.dark-only .u-pearl.error .u-pearl-number {
  background-color: #111727;
}

body.dark-only .docs-data .input-group-text {
  color: #e6edef;
}

body.dark-only .options > div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .progress {
  background-color: #202938;
}

body.dark-only .dataTables_wrapper table.dataTable tbody td,
body.dark-only .dataTables_wrapper table.dataTable tbody th {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .cd-timeline-content {
  background-color: #111727;
}

body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  .calendar-wrap
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-right-single-arrow:after {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .pagination-primary .page-item .page-link {
  background-color: #111727;
  border-color: #202938;
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .support-ticket .pro-gress ul li {
  color: #98a6ad;
}

body.dark-only .bookmark-tabcontent .tab-content .card .card-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .bookmark-wrap .left-bookmark ul li a > .title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .bookmark-wrap .left-bookmark ul li button.badge-light {
  background-color: rgba(36, 105, 92, 0.2);
  color: #24695c;
}

body.dark-only .chat-box .chat-right-aside .chat .chat-msg-box .other-message {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu .user-profile .follow {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .chat-box .chat-menu .user-profile .follow .follow-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .chat-box .chat-menu .user-profile .image .icon-wrapper {
  background-color: #202938;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark-only .chat-box .chat-menu .user-profile .digits {
  border-color: #202938;
}

body.dark-only .social-status form .form-group .form-control-social {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .search-page .search-form .form-group .input-group-text {
  background-color: #202938;
}

body.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .filter-cards-view {
  background-color: #111727;
  border: none;
}

body.dark-only
  .product-wrapper.sidebaron
  .product-sidebar
  .filter-section
  .card
  .left-filter {
  border: none;
}

body.dark-only .product-page-main .pro-group:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .prooduct-details-box .media {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .invoice .invo-header {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .order-history table .qty-box .input-group {
  border-color: rgba(36, 105, 92, 0.2);
}

@media only screen and (max-width: 1199px) {
  body.dark-only .sm {
    background-color: #111727;
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap .mobile-back {
    border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap ul li {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .pixelstrap ul a {
    background: #111727;
  }
  body.dark-only .pixelstrap ul a:hover,
  body.dark-only .pixelstrap ul a:focus,
  body.dark-only .pixelstrap ul a:active {
    background: #111727;
  }
  body.dark-only .chat-menu {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
    background-color: #111727;
  }
  body.dark-only .error-wrapper .maintenance-icons li i {
    color: #2c323f;
  }
  body.dark-only .widget-joins .border-after-xs:after {
    background-color: rgba(36, 105, 92, 0.2);
  }
}

@media only screen and (max-width: 991px) {
  body.dark-only
    .page-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul {
    border-top: 1px solid rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .page-wrapper.horizontal-wrapper,
body.dark-only .compact-wrapper {
  /* Main Header start */
  /* Main Header ends */
}

body.dark-only .page-wrapper.horizontal-wrapper .page-main-header,
body.dark-only .compact-wrapper .page-main-header {
  background-color: #20293800;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right,
body.dark-only .compact-wrapper .page-main-header .main-header-right {
  background-color: #111727;
  -webkit-box-shadow: 0 0 18px 3px #2a323d57;
  box-shadow: 0 0 18px 3px #2a323d57;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  li
  i,
body.dark-only .compact-wrapper .page-main-header .main-header-right li i {
  color: #e6edef;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu {
  background-color: #111727;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul.click-menu
  > li
  ul.submenu
  li
  a {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:before {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  svg,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  svg {
  stroke: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:last-child {
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:first-child {
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .media
  .media-body
  .txt-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px #111727;
  box-shadow: 0 0 2px 2px #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  p,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div
  p {
  color: #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:before {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:after,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .onhover-show-div:after {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li
  span,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li:hover,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  li:hover {
  background-color: #282e38;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  .bg-light,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .notification-dropdown.onhover-show-div
  .bg-light {
  background-color: #282e38 !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .language-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .nav-menus
  li
  .language-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  circle,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a
  svg
  circle {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  circle,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .profile-dropdown
  li
  a:hover
  svg
  circle {
  color: #24695c !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .media
  .media-body
  .txt-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .media
  .media-body
  .txt-dark {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px #111727;
  box-shadow: 0 0 2px 2px #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:before {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:after,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .onhover-show-div:after {
  border-bottom: 7px solid #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li
  span,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .notification-dropdown.onhover-show-div
  li
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .language-dropdown
  li
  a,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li
  .language-dropdown
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  + li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  + li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .chat-dropdown
  li
  + li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .nav-right
  .notification-dropdown
  li
  + li {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .mega-menu-header
  .vertical-menu-main
  .main-nav
  .main-menu
  li,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .mega-menu-header
  .vertical-menu-main
  .main-nav
  .main-menu
  li {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-right
  .left-menu-header
  ul
  li
  .search-form
  input {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left,
body.dark-only .compact-wrapper .page-main-header .main-header-left {
  background-color: #73276b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-dark,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-dark {
  display: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-light,
body.dark-only
  .compact-wrapper
  .page-main-header
  .main-header-left
  .logo-wrapper
  a
  .image-light {
  display: block;
}

@media only screen and (max-width: 991px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul,
  body.dark-only
    .compact-wrapper
    .page-main-header
    .main-header-right
    .nav-right
    > ul {
    background-color: #111727;
    -webkit-box-shadow: 0 2px 2px 2px rgba(36, 105, 92, 0.2);
    box-shadow: 0 2px 2px 2px rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper,
body.dark-only .compact-wrapper .page-body-wrapper {
  /* body start*/
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .header-small,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .header-small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .statistics
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .statistics p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .professor-block
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .professor-block
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .feather-main
  .professor-block
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  .professor-block
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .logs-element
  span
  + span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .logs-element
  span
  + span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-block
  .progress-title
  span
  + span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-block
  .progress-title
  span
  + span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .ecommerce-icons
  div
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .ecommerce-icons
  div
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .new-users
  .media
  .media-body
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .recent-notification
  .media
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .new-users
  .media
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .recent-notification
  .media
  .media-body
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .media
  .media-body
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .media
  .media-body
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .progress-change
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .progress-media
  .progress-change
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr
  td {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr:last-child
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .topper-lists
  table
  tbody
  tr:last-child
  td {
  border-bottom: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .notifiaction-media
  .media
  .media-body
  .circle-left,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .notifiaction-media
  .media
  .media-body
  .circle-left {
  border: 5px solid #334154;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .upcoming-event
  .upcoming-innner:last-child {
  border-bottom: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  table
  tbody
  tr
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .professor-table
  table
  tbody
  tr
  td {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .number-widgets
  .media
  .media-body
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .number-widgets
  .media
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.gradient-line-1:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.small-line:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.medium-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.gradient-line-1:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.small-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .gradient-round.medium-line:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .media-body
  h6
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .activity
  .media
  .media-body
  h6
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card.custom-card
  .card-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card.custom-card
  .card-header {
  border-bottom: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-card
  .card-header
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .custom-card
  .card-header
  img {
  background-color: #000;
  opacity: 0.8;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-card
  .profile-details
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .custom-card
  .profile-details
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .row
  h3
  small,
body.dark-only .compact-wrapper .page-body-wrapper .page-header .row h3 small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb
  li,
body.dark-only .compact-wrapper .page-body-wrapper .page-header .breadcrumb li {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-header
  .breadcrumb-item.active {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body,
body.dark-only .compact-wrapper .page-body-wrapper .page-body {
  background-color: #202938;
  /* body end*/
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card {
  background-color: #111727;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > g
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  #bar-chart2
  svg
  > g
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-block
  .word-tree
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header {
  background-color: transparent;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  > span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  > span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h3,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h4,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-header
  .card-header-right
  i {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-dark
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline-2x,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.inverse,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.outline-2x,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #animation-box
  .animate-widget
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #animation-box
  .animate-widget
  p {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .grid-item
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .grid-item
  img {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .line,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .line {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  td,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .table th,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .table td {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  th {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  border-top: none;
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  .border-bottom-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  thead
  .border-bottom-primary
  th {
  border-bottom: 1px solid #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-primary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-secondary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-success
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-info
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-warning
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-light
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .table-danger
  td {
  color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-primary,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-secondary,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-success,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-info,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-warning,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-danger,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-primary,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-secondary,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-success,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-info,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-warning,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-danger {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-light,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .bg-light {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .thead-light
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .thead-light
  th {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .double,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dotted,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dashed,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .double,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dotted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  .dashed {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  tbody
  .border-bottom-primary
  td {
  border-bottom: 1px solid #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  > :not(:last-child)
  > :last-child
  > *,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table
  > :not(:last-child)
  > :last-child
  > * {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table[class*="bg-"]
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-striped
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-bordered
  th {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-inverse
  tbody
  tr:nth-of-type(odd):hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-inverse
  tbody
  tr:nth-of-type(odd):hover
  td {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  tr,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-border-vertical
  td {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  thead
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .table-styling
  tbody
  td {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-footer,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .card-footer {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  .switch-state,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  .switch-state {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  input:checked
  + .switch-state,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .switch
  input:checked
  + .switch-state {
  background-color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bg-white,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .bg-white {
  background-color: #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .b-l-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .b-l-light {
  border-left: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ct-grid,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .ct-grid {
  stroke: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ct-label,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .ct-label {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  hr,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card hr {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .text-muted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .calender-widget
  .cal-date,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .calender-widget
  .cal-date {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form
  .form-icon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .contact-form
  .theme-form
  .form-icon {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-dark,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-dark {
  color: #fff !important;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .btn-outline-light:hover {
  color: #000 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-right,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-right {
  border-right: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  .flot-text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  .flot-text {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-placeholder
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  svg
  g
  rect:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  svg
  g
  rect:first-child {
  fill: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  rect:nth-child(6),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#gantt_chart
  rect:nth-child(6) {
  fill: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#line-chart
  rect,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart1
  rect,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart2
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#line-chart
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart1
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow#column-chart2
  rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > rect {
  fill: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > g
  > g
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > g
  > g
  text {
  fill: #b1b1b2;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-overflow
  svg
  > g
  > text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .radial-bar:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .radial-bar:after {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num
  .color-bottom,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .bar-chart-widget
  .bottom-content
  .num
  .color-bottom {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .b-r-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .b-r-light {
  border-right: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-labels
  .ct-label,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-labels
  .ct-label,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-labels
  .ct-label {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-grid,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .live-products
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .turnover
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .monthly
  .ct-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  .uses
  .ct-grid {
  stroke: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #browser-uses-chart
  svg
  text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #website-visiter-chart
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #browser-uses-chart
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .chart-container
  #website-visiter-chart
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .status-details
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .clipboaard-container
  .form-control,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .clipboaard-container
  .form-control {
  background-color: #202938;
  color: #98a6ad;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .img-thumbnail,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .img-thumbnail {
  background-color: rgba(36, 105, 92, 0.2);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  button,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  button {
  color: #000;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-danger,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-success,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-danger,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .btn-success {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length
  select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_info,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_processing,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_length,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_info,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_processing,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter
  input[type="search"],
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_filter
  input[type="search"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr:hover
  > .sorting_1 {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd {
  background-color: #1f232b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.odd
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.odd
  > .sorting_1 {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.stripe
  tbody
  tr.even
  > .sorting_1 {
  background-color: #22262f;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  thead
  td {
  border-bottom: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  tr,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  tr {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  td.select-checkbox:before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  th.select-checkbox:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  td.select-checkbox:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable
  tbody
  th.select-checkbox:before {
  border: 1px solid #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .table-striped
  tbody
  tr:nth-of-type(odd),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .table-striped
  tbody
  tr:nth-of-type(odd) {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:active {
  border-color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  :active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled
  :active {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.row-border
  tbody
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.hover
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr.even:hover
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column.hover
  tbody
  tr.even:hover
  > .sorting_1 {
  background-color: #191e27;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.hover
  tbody
  tr:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.hover
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr:hover {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th:first-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  th:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.cell-border
  td:first-child {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_3,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_2,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.order-column
  tbody
  tr
  > .sorting_3,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .dataTables_wrapper
  table.dataTable.display
  tbody
  tr
  > .sorting_3 {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #example-style-3_wrapper
  #example-style-3
  tfoot,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #example-style-3_wrapper
  #example-style-3
  tfoot {
  border-top: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-link,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .page-link {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.disabled
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.disabled
  .page-link {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-link,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .card .page-link {
  color: rgba(255, 255, 255, 0.7);
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item:hover
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item:hover
  .page-link {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.active
  .page-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .page-item.active
  .page-link {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .icon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .icon {
  color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  .total-num
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .ecommerce-widget
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-container-small,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .flot-chart-container-small {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  span,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  #API-2_wrapper
  #API-2
  tbody
  td
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .product-table
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-item
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-item
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link.active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:focus,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:focus,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .border-tab.nav-tabs
  .nav-link:hover {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-selected,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-movie
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-pill
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-reversed
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-horizontal
  .br-widget
  a.br-selected {
  background-color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a {
  border: 2px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-active,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-square
  .br-widget
  a.br-selected {
  border: 2px solid #24695c;
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a:after {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-selected:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-active:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-selected:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-active:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-selected:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a.br-active:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-selected:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars-o
  .br-widget
  a.br-active:after {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .scroll-demo,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .scroll-demo {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  input {
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  .form-group:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .search-form
  .form-group:before {
  background: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-content::before {
  border-left: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .cd-timeline-block:nth-child(even)
  .cd-timeline-content::before {
  border-right-color: rgba(36, 105, 92, 0.2);
  border-left: transparent;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .breadcrumb.bg-white,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .breadcrumb.bg-white {
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .user-status
  table
  th {
  border-top: none !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris-daily
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #browser-uses-chart
  svg,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card
  #website-visiter-chart
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris-daily
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #donut-color-chart-morris
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #browser-uses-chart
  svg,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  #website-visiter-chart
  svg {
  opacity: 0.5;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-header-1,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-2-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-header-1,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-2-header {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .show
  > .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .show
  > .nav-link {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-link.active {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .show
  > .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .show
  > .nav-link {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .nav-primary
  .nav-pills.nav-primary
  .nav-link.active {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .drag-box
  fieldset,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .drag-box
  fieldset {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .help-block,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder
  .help-block {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #viewhtml
  .render,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #viewhtml
  .render {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-column
  .drag-bx,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .form-builder-column
  .drag-bx {
  border: 1px dotted rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .wizard-4
  ul.anchor
  li
  a.disabled,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .wizard-4
  ul.anchor
  li
  a.disabled {
  color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  a
  > div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  a
  > div {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .gallery-with-description
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-header,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-header {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  > .jsgrid-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-header-cell
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  > .jsgrid-cell
  input {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  select,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-header-row
  select,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-filter-row
  select {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-row
  > .jsgrid-cell {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-alt-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-alt-row
  > .jsgrid-cell {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-cell,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-body,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-grid-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-selected-row
  > .jsgrid-cell,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-selected-row
  > .jsgrid-cell {
  background-color: #1c212b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  [class*="jsgrid-pager"],
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  [class*="jsgrid-pager"] {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-pager-current-page,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .jsgrid-pager-current-page {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .sub-title,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .sub-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .navs-icon li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  li
  a:hover {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  .separator,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon
  .separator {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  li
  .text-muted,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  li
  .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon.default-according.style-1
  li
  button:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-icon.default-according.style-1
  li
  button:hover {
  color: #24695c !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .nav-list
  .nav-list-disc
  li:hover
  a {
  color: #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div {
  background-color: #202938;
  -webkit-box-shadow: 0 0 2px 2px rgba(36, 105, 92, 0.2);
  box-shadow: 0 0 2px 2px rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  p {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polyline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polygon,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polyline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  polygon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a
  svg
  rect {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  path,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  line,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polyline,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polygon,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  rect,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  path,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  line,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polyline,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  polygon,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .navs-dropdown
  .onhover-show-div
  .navs-icon
  li
  a:hover
  svg
  rect {
  color: #24695c !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .onhover-show-div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .onhover-show-div {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .onhover-dropdown:hover
  .onhover-show-div:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .onhover-dropdown:hover
  .onhover-show-div:before {
  border-bottom: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card {
  -webkit-box-shadow: 0 0 1px 1px #374252 !important;
  box-shadow: 0 0 1px 1px #374252 !important;
  border: none !important;
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .btn-link,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .btn-link {
  color: rgba(255, 255, 255, 0.7);
  border: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .default-according
  .card
  .card-body {
  color: #98a6ad;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .border,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .border {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-date,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-date {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li {
  color: #98a6ad;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:first-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:last-child,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .blog-social
  li:last-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .blog-social
  li:last-child {
  border-right: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top
  p,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details
  .single-blog-content-top
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-box
  .blog-details-main
  .single-blog-content-top
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .blog-list
  .blog-details
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .blog-list
  .blog-details
  h6 {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_frame,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .cke_wysiwyg_frame,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .cke_wysiwyg_div {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  form
  .form-group
  .form-control,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  form
  .form-group
  .form-control {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  .cke_top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  .cke_top {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  #cke_text-box,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .add-post
  #cke_text-box {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  h4,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box h4 {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  h6
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  h6
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .media
  img {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li:first-child,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  .comment-social
  li:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .comment-box
  hr,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .comment-box hr {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  td,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  th,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  td,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .table-hover
  tbody
  tr:hover
  th {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li,
body.dark-only .compact-wrapper .page-body-wrapper .page-body ul.the-icons li {
  border: 1px dotted rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  padding: 10px;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li:hover {
  background: #000;
  -webkit-box-shadow: 0 0 3px #111727;
  box-shadow: 0 0 3px #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  ul.the-icons
  li
  em {
  display: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .box,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .box {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .button-preview
  h2,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  .button-preview
  h2 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  pre.well,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .button-builder-wrap
  pre.well {
  background-color: #111727 !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  + li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  + li {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  > li
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  ul.dates
  li,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .crm-activity
  ul.dates
  li {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris
  svg
  text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris-daily
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris
  svg
  text,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  #donut-color-chart-morris-daily
  svg
  text {
  fill: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-profile
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-profile
  img {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .profile-details
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .profile-details
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-social
  li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-social
  li
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  + div,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .custom-card
  .card-footer
  > div
  + div {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .form-control,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  select,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .form-control,
body.dark-only .compact-wrapper .page-body-wrapper .page-body select {
  background-color: #1f2939;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .checkbox
  label::before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .radio
  label::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .radio
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  a:hover {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  .dropdown-divider,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-basic
  .dropdown
  .dropdown-content
  .dropdown-divider {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a:hover,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropup-basic
  .dropup
  .dropup-content
  a.active {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone.dz-clickable
  .dz-message
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview
  .dz-details,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropzone
  .dz-preview
  .dz-details {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body {
  -webkit-column-rule: 1px solid rgba(36, 105, 92, 0.2);
  -moz-column-rule: 1px solid rgba(36, 105, 92, 0.2);
  column-rule: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  h4
  span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .browser-widget
  .media-body
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_top {
  border-color: rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_chrome,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .cke_chrome {
  border-color: rgba(36, 105, 92, 0.2) !important;
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .media
  img,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .media
  img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-app-sidebar
  .main-menu
  > li
  a:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media.active,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media.active {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-right-aside
  .email-body
  .inbox
  .media:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  h6
  small
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  p,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .media-body
  p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-top,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-top {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .email-wrap p {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-content
  .email-top
  .user-emailid:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-content
  .email-top
  .user-emailid:after {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-wrapper
  .emailread-group:nth-child(n + 2),
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .email-wrap
  .email-wrapper
  .emailread-group:nth-child(n + 2) {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .dropdown-menu {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .dropdown-menu a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .dropdown-menu
  a:hover {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div
  i {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div:hover,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .icon-lists
  div:hover {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .media-body
  > span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .media-body
  > span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .details,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins
  .media
  .details {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:before,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .widget-joins:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .redial-social-widget
  i,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .redial-social-widget
  i {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h4,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .social-widget-card
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .b-b-light,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .b-b-light {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .b-r-dark,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .b-r-dark {
  border-right: 1px solid #fff !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  i,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  p,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  h5,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .testimonial h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .testimonial
  span {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .grid-showcase
  span,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .grid-showcase
  span {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .grid-align
  .row,
body.dark-only .compact-wrapper .page-body-wrapper .page-body .grid-align .row {
  background-color: rgba(36, 105, 92, 0.2);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .border-style
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .border-style
  .card {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .offer-style
  .card,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .offer-style
  .card {
  border: 1px dashed rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-grid,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-grid {
  background-color: #111727;
  color: #98a6ad !important;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-mainControls
  .ge-addRowGroup
  .ge-row-icon
  .column,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-mainControls
  .ge-addRowGroup
  .ge-row-icon
  .column {
  border-left: 3px solid #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-code,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-screen,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-code,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .btn-screen {
  color: #98a6ad;
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .row,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .row {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .column,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .column {
  border: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  input,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  input {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  .btn-group
  a,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-canvas.ge-editing
  .ge-tools-drawer
  .ge-details
  .btn-group
  a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-content-type-ckeditor,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .page-builder
  .ge-content-type-ckeditor {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .search-page
  .info-block
  + .info-block,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .search-page
  .info-block
  + .info-block {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-primary
  h5,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-secondary
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-primary
  h5,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card-absolute
  .bg-secondary
  h5 {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.timeline-line-1:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.small-line:after,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.medium-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.timeline-line-1:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.small-line:after,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .timeline-small
  .media
  .timeline-round.medium-line:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper footer,
body.dark-only .compact-wrapper .page-body-wrapper footer {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper footer p,
body.dark-only .compact-wrapper .page-body-wrapper footer p {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-select,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .page-body-wrapper
  .custom-file-label,
body.dark-only .compact-wrapper .page-body-wrapper .custom-select,
body.dark-only .compact-wrapper .page-body-wrapper .custom-file-label {
  background: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .page-body-wrapper .footer-fix,
body.dark-only .compact-wrapper .page-body-wrapper .footer-fix {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .dt-button-info,
body.dark-only .compact-wrapper .dt-button-info {
  background-color: #111727;
  border: 1px solid #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .dt-button-info h2,
body.dark-only .compact-wrapper .dt-button-info h2 {
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .about .name,
body.dark-only .compact-wrapper .chat-box .about .name {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .chat-menu,
body.dark-only .compact-wrapper .chat-box .chat-menu {
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .chat-menu .nav-tabs,
body.dark-only .compact-wrapper .chat-box .chat-menu .nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-menu
  .nav-tabs
  .nav-item
  .nav-link.active,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-menu
  .nav-tabs
  .nav-item
  .nav-link.active {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .status,
body.dark-only .compact-wrapper .chat-box .status {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .chat-box .status p,
body.dark-only .compact-wrapper .chat-box .status p {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header,
body.dark-only .compact-wrapper .chat-box .chat-right-aside .chat .chat-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li
  a
  i,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  .chat-menu-icons
  li
  a
  i {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  img,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-header
  img {
  -webkit-box-shadow: 1px 1px 4px 1px #202938;
  box-shadow: 1px 1px 4px 1px #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .my-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .my-message {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .message {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .other-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-msg-box
  .other-message {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message {
  background-color: #111727;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message
  .input-group
  .form-control,
body.dark-only
  .compact-wrapper
  .chat-box
  .chat-right-aside
  .chat
  .chat-message
  .input-group
  .form-control {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-history
  .call-icons
  ul
  li,
body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .chat-history
  .call-icons
  ul
  li
  a,
body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li a {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message {
  background-color: #1c222b;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message
  .chat-user-img,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.my-message
  .chat-user-img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.other-message
  .chat-user-img,
body.dark-only
  .compact-wrapper
  .chat-box
  .bitcoin-chat
  .chat
  .chat-msg-box
  .message.other-message
  .chat-user-img {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper pre,
body.dark-only .compact-wrapper pre {
  background-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .scorlled,
body.dark-only .compact-wrapper .scorlled {
  background-color: #202938;
}

body.dark-only .page-wrapper.horizontal-wrapper .input-group-air,
body.dark-only .compact-wrapper .input-group-air {
  -webkit-box-shadow: 0 3px 20px 0 rgba(36, 105, 92, 0.2);
  box-shadow: 0 3px 20px 0 rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .input-group-solid
  .input-group-text,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .input-group-solid
  .form-control,
body.dark-only .compact-wrapper .input-group-solid .input-group-text,
body.dark-only .compact-wrapper .input-group-solid .form-control {
  background: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .semilight-bg-color,
body.dark-only .page-wrapper.horizontal-wrapper .header-bg-color,
body.dark-only .compact-wrapper .semilight-bg-color,
body.dark-only .compact-wrapper .header-bg-color {
  background-color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item,
body.dark-only .compact-wrapper .list-group-item {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item.active,
body.dark-only .compact-wrapper .list-group-item.active {
  background-color: #24695c !important;
  border-color: #24695c;
  color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item:hover,
body.dark-only .page-wrapper.horizontal-wrapper .list-group-item:focus,
body.dark-only .compact-wrapper .list-group-item:hover,
body.dark-only .compact-wrapper .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-group-item-action:hover:not(.active),
body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-action:focus,
body.dark-only .compact-wrapper .list-group-item-action:hover:not(.active),
body.dark-only .compact-wrapper .list-group-item-action:focus {
  background-color: #202938;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-group-flush
  .list-group-item,
body.dark-only .compact-wrapper .list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-primary,
body.dark-only .compact-wrapper .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-secondary,
body.dark-only .compact-wrapper .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-success,
body.dark-only .compact-wrapper .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-danger,
body.dark-only .compact-wrapper .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-warning,
body.dark-only .compact-wrapper .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-info,
body.dark-only .compact-wrapper .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-light,
body.dark-only .compact-wrapper .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

body.dark-only .page-wrapper.horizontal-wrapper .list-group-item-dark,
body.dark-only .compact-wrapper .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg,
body.dark-only .compact-wrapper .auth-bg {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card,
body.dark-only .compact-wrapper .auth-bg .authentication-box .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  input,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  input {
  color: rgba(255, 255, 255, 0.7);
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  label,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .form-group
  label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .auth-bg
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h3,
body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h4,
body.dark-only .page-wrapper.horizontal-wrapper .auth-bg .authentication-box h6,
body.dark-only .compact-wrapper .auth-bg .authentication-box h3,
body.dark-only .compact-wrapper .auth-bg .authentication-box h4,
body.dark-only .compact-wrapper .auth-bg .authentication-box h6 {
  color: #fff;
}

body.dark-only .page-wrapper.horizontal-wrapper .auth-bg-video,
body.dark-only .compact-wrapper .auth-bg-video {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h4,
body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h3,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h4,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h3 {
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  h6,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card,
body.dark-only .compact-wrapper .auth-bg-video .authentication-box .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"],
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .auth-bg-video
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .error-wrapper
  .maintenance-icons
  li
  i,
body.dark-only .compact-wrapper .error-wrapper .maintenance-icons li i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .error-wrapper
  .maintenance-icons
  li:nth-child(3)
  i,
body.dark-only
  .compact-wrapper
  .error-wrapper
  .maintenance-icons
  li:nth-child(3)
  i {
  color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content,
body.dark-only .compact-wrapper .modal-content {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content .modal-header,
body.dark-only .compact-wrapper .modal-content .modal-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .modal-content
  .modal-header
  .close,
body.dark-only .compact-wrapper .modal-content .modal-header .close {
  color: #98a6ad;
}

body.dark-only .page-wrapper.horizontal-wrapper .modal-content .modal-footer,
body.dark-only .compact-wrapper .modal-content .modal-footer {
  background-color: #111727;
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .btn-close,
body.dark-only .compact-wrapper .btn-close {
  -webkit-filter: invert(1);
  filter: invert(1);
}

body.dark-only .page-wrapper.horizontal-wrapper .comingsoon,
body.dark-only .compact-wrapper .comingsoon {
  background-blend-mode: overlay;
  background-color: rgba(54, 64, 74, 0.9);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  h5,
body.dark-only .compact-wrapper .comingsoon .comingsoon-inner h5 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown,
body.dark-only .compact-wrapper .comingsoon .comingsoon-inner .countdown {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown
  .title,
body.dark-only
  .compact-wrapper
  .comingsoon
  .comingsoon-inner
  .countdown
  .title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .comingsoon
  .comingsoon-inner
  .coming-soon-bottom-link
  a,
body.dark-only
  .compact-wrapper
  .comingsoon
  .comingsoon-inner
  .coming-soon-bottom-link
  a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .theme-form .login-divider,
body.dark-only .compact-wrapper .theme-form .login-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .theme-form
  .login-divider:before,
body.dark-only .compact-wrapper .theme-form .login-divider:before {
  background: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .authentication-main,
body.dark-only .compact-wrapper .authentication-main {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .card,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .reset-password-link,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .reset-password-link {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  label,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  label {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  .form-control,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .form-group
  .form-control {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .opt-box,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .reset-password-box
  .theme-form
  .opt-box {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h4,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h4 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h6,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h6 {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h3,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  h3 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"],
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="text"],
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .form-group
  input[type="password"] {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before,
body.dark-only
  .compact-wrapper
  .authentication-main
  .auth-innerright
  .authentication-box
  .card
  .theme-form
  .checkbox
  label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .vertical-menu-main,
body.dark-only .compact-wrapper .vertical-menu-main {
  background-color: #111727;
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a,
body.dark-only .compact-wrapper .pixelstrap a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:focus,
body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:active,
body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap a:hover,
body.dark-only .compact-wrapper .pixelstrap a:focus,
body.dark-only .compact-wrapper .pixelstrap a:active,
body.dark-only .compact-wrapper .pixelstrap a:hover {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .pixelstrap
  > li
  > a.highlighted:before,
body.dark-only .compact-wrapper .pixelstrap > li > a.highlighted:before {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .pixelstrap
  > li
  > a.highlighted:after,
body.dark-only .compact-wrapper .pixelstrap > li > a.highlighted:after {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .border-top,
body.dark-only .compact-wrapper .border-top {
  border-top: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .page-wrapper.horizontal-wrapper .table td,
body.dark-only .page-wrapper.horizontal-wrapper .table th,
body.dark-only .compact-wrapper .table td,
body.dark-only .compact-wrapper .table th {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .breadcrumb,
body.dark-only .compact-wrapper .breadcrumb {
  background-color: #202938;
}

body.dark-only .page-wrapper.horizontal-wrapper .table-bordered,
body.dark-only .compact-wrapper .table-bordered {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal tr,
body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal th,
body.dark-only .page-wrapper.horizontal-wrapper .table-border-horizontal td,
body.dark-only .compact-wrapper .table-border-horizontal tr,
body.dark-only .compact-wrapper .table-border-horizontal th,
body.dark-only .compact-wrapper .table-border-horizontal td {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper thead,
body.dark-only .page-wrapper.horizontal-wrapper tbody,
body.dark-only .page-wrapper.horizontal-wrapper tfoot,
body.dark-only .page-wrapper.horizontal-wrapper tr,
body.dark-only .page-wrapper.horizontal-wrapper td,
body.dark-only .page-wrapper.horizontal-wrapper th,
body.dark-only .compact-wrapper thead,
body.dark-only .compact-wrapper tbody,
body.dark-only .compact-wrapper tfoot,
body.dark-only .compact-wrapper tr,
body.dark-only .compact-wrapper td,
body.dark-only .compact-wrapper th {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .btn-light,
body.dark-only .compact-wrapper .btn-light {
  background-color: #202938 !important;
  border-color: rgba(36, 105, 92, 0.2) !important;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .page-wrapper.horizontal-wrapper .profile-greeting .btn-light,
body.dark-only .compact-wrapper .profile-greeting .btn-light {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #24695c !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .note-editor.note-frame
  .note-statusbar,
body.dark-only .compact-wrapper .note-editor.note-frame .note-statusbar {
  background-color: #202938;
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .note-editor.note-frame
  .note-status-output,
body.dark-only .compact-wrapper .note-editor.note-frame .note-status-output {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .page-wrapper.horizontal-wrapper .contacts-tabs .nav-pills,
body.dark-only .compact-wrapper .contacts-tabs .nav-pills {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .contacts-tabs
  .nav-pills
  .nav-link
  + .nav-link,
body.dark-only
  .compact-wrapper
  .contacts-tabs
  .nav-pills
  .nav-link
  + .nav-link {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .list-persons
  .profile-mail
  .media
  .media-body
  ul
  li
  + li,
body.dark-only
  .compact-wrapper
  .list-persons
  .profile-mail
  .media
  .media-body
  ul
  li
  + li {
  border-left: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .blog-box.blog-shadow
  .blog-details
  h4,
body.dark-only .compact-wrapper .blog-box.blog-shadow .blog-details h4 {
  color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 1366px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .earning-card
    .card-body
    > .row
    .border-right,
  body.dark-only
    .compact-wrapper
    .earning-card
    .card-body
    > .row
    .border-right {
    border-bottom: 1px solid rgba(36, 105, 92, 0.2) !important;
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chart_data_left
    .card-body
    .chart-main
    .media,
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chart_data_right
    .card-body
    .chart-main
    .media,
  body.dark-only
    .compact-wrapper
    .chart_data_left
    .card-body
    .chart-main
    .media,
  body.dark-only
    .compact-wrapper
    .chart_data_right
    .card-body
    .chart-main
    .media {
    border-right: 1px solid rgba(36, 105, 92, 0.2);
  }
}

@media (min-width: 1200px) {
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul,
  body.dark-only .compact-wrapper .pixelstrap ul {
    background: #202938;
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:hover,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:focus,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a:active,
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a.highlighted,
  body.dark-only .compact-wrapper .pixelstrap ul a:hover,
  body.dark-only .compact-wrapper .pixelstrap ul a:focus,
  body.dark-only .compact-wrapper .pixelstrap ul a:active,
  body.dark-only .compact-wrapper .pixelstrap ul a.highlighted {
    color: #24695c;
  }
  body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul a.current,
  body.dark-only .compact-wrapper .pixelstrap ul a.current {
    background-color: #202938;
    color: #24695c;
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .mega-menu .title,
body.dark-only .compact-wrapper .mega-menu .title {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username,
body.dark-only
  .compact-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username
  small,
body.dark-only
  .compact-wrapper
  .mega-menu
  .galleria-list
  .galleria
  > div
  .username
  small {
  color: #98a6ad;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .mega-menu
  .list-unstyled
  div
  a:hover,
body.dark-only .compact-wrapper .mega-menu .list-unstyled div a:hover {
  color: #24695c;
}

body.dark-only .page-wrapper.horizontal-wrapper .default-according .card,
body.dark-only .compact-wrapper .default-according .card {
  background-color: #111727;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .card
  .btn-link,
body.dark-only .compact-wrapper .default-according .card .btn-link {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: #fff;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .card
  .text-muted,
body.dark-only .compact-wrapper .default-according .card .text-muted {
  color: #98a6ad !important;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .bg-primary
  .btn-link,
body.dark-only .compact-wrapper .default-according .bg-primary .btn-link {
  background-color: #24695c;
  border: 1px solid #24695c;
}

body.dark-only
  .page-wrapper.horizontal-wrapper
  .default-according
  .bg-secondary
  .btn-link,
body.dark-only .compact-wrapper .default-according .bg-secondary .btn-link {
  background-color: #ba895d;
  border: 1px solid #ba895d;
}

body.dark-only .page-wrapper.horizontal-wrapper .collapse .card-body,
body.dark-only .compact-wrapper .collapse .card-body {
  background-color: #111727;
}

@media screen and (max-width: 1660px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .call-icons
    ul
    li,
  body.dark-only .compact-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid rgba(36, 105, 92, 0.2);
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .total-time
    h2,
  body.dark-only .compact-wrapper .chat-box .chat-history .total-time h2 {
    color: rgba(255, 255, 255, 0.7);
  }
  body.dark-only .page-wrapper.horizontal-wrapper .caller-img img,
  body.dark-only .compact-wrapper .caller-img img {
    opacity: 0.7;
  }
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .chat-box
    .chat-history
    .call-content
    > div,
  body.dark-only .compact-wrapper .chat-box .chat-history .call-content > div {
    background-color: #111727;
    background-blend-mode: overlay;
  }
}

body.dark-only .page-wrapper.horizontal-wrapper .pixelstrap ul,
body.dark-only .compact-wrapper .pixelstrap ul {
  background: #111727;
}

@media only screen and (max-width: 575px) {
  body.dark-only
    .page-wrapper.horizontal-wrapper
    .widget-joins
    .border-after-xs:after,
  body.dark-only .compact-wrapper .widget-joins .border-after-xs:after {
    background-color: rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .lg-backdrop {
  background-color: #202938;
}

body.dark-only .lg-outer .lg-thumb-outer {
  background-color: #111727;
}

body.dark-only .drag {
  background-color: #111727;
}

body.dark-only .drag form {
  background: #111727;
  -webkit-box-shadow: 0 0 30px rgba(36, 105, 92, 0.2);
  box-shadow: 0 0 30px rgba(36, 105, 92, 0.2);
  border: 1px dotted rgba(36, 105, 92, 0.2);
}

body.dark-only .drag form input {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .drag form .help-block {
  text-transform: capitalize;
  color: #98a6ad;
}

body.dark-only .draggable input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .draggable p {
  color: #98a6ad;
}

body.dark-only .draggable select {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .draggable .radio label::before,
body.dark-only .draggable .checkbox label::before {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .select2-container--default .select2-selection--multiple,
body.dark-only .select2-container--default .select2-selection--single {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .form-bookmark
  .form-group
  .select2-container
  .select2-selection--single {
  border-color: rgba(255, 255, 255, 0.7);
}

body.dark-only .select2-dropdown {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .select2-drpdwn .form-control-primary {
  border-color: #24695c !important;
  color: #24695c !important;
}

body.dark-only .select2-drpdwn .form-control-secondary {
  border-color: #ba895d !important;
  color: #ba895d !important;
}

body.dark-only .select2-drpdwn .form-control-success {
  border-color: #1b4c43 !important;
  color: #1b4c43 !important;
}

body.dark-only .select2-drpdwn .form-control-info {
  border-color: #717171 !important;
  color: #717171 !important;
}

body.dark-only .select2-drpdwn .form-control-warning {
  border-color: #e2c636 !important;
  color: #e2c636 !important;
}

body.dark-only .select2-drpdwn .form-control-danger {
  border-color: #d22d3d !important;
  color: #d22d3d !important;
}

body.dark-only .select2-drpdwn .form-control-inverse {
  border-color: #fff !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-primary-fill {
  background-color: #24695c !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-secondary-fill {
  background-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-success-fill {
  background-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-info-fill {
  background-color: #717171 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-warning-fill {
  background-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-danger-fill {
  background-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only .select2-drpdwn .form-control-inverse-fill {
  background-color: #fff !important;
  color: #000 !important;
}

body.dark-only
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only
  .theme-form
  .form-group
  select.form-control:not([size]):not([multiple]) {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-group input[type="text"],
body.dark-only .theme-form .form-group input[type="email"],
body.dark-only .theme-form .form-group input[type="search"],
body.dark-only .theme-form .form-group input[type="password"],
body.dark-only .theme-form .form-group input[type="number"],
body.dark-only .theme-form .form-group input[type="tel"],
body.dark-only .theme-form .form-group input[type="date"],
body.dark-only .theme-form .form-group input[type="datetime-local"],
body.dark-only .theme-form .form-group input[type="time"],
body.dark-only .theme-form .form-group input[type="datetime-local"],
body.dark-only .theme-form .form-group input[type="month"],
body.dark-only .theme-form .form-group input[type="week"],
body.dark-only .theme-form .form-group input[type="url"],
body.dark-only .theme-form .form-group input[type="file"],
body.dark-only .theme-form .form-group select {
  border-color: rgba(36, 105, 92, 0.2);
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .theme-form
  .form-group
  input[type="text"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="email"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="search"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="password"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="number"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="tel"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="date"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="datetime-local"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="time"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="datetime-local"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="month"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="week"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="url"]::-webkit-input-placeholder,
body.dark-only
  .theme-form
  .form-group
  input[type="file"]::-webkit-input-placeholder,
body.dark-only .theme-form .form-group select::-webkit-input-placeholder {
  color: #98a6ad;
}

body.dark-only .theme-form .form-group .form-control {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .theme-form .form-group textarea {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .theme-form .form-divider::before {
  background: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .CodeMirror {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .CodeMirror .CodeMirror-code pre {
  background: #111727;
}

body.dark-only .editor-statusbar {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-left: 1px solid rgba(36, 105, 92, 0.2);
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar a {
  color: rgba(255, 255, 255, 0.7) !important;
}

body.dark-only .editor-toolbar a:hover,
body.dark-only .editor-toolbar a.active {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-toolbar i.separator {
  border-left: 1px solid #98a6ad;
  border-right: 1px solid #98a6ad;
}

body.dark-only .editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .editor-preview {
  background-color: #111727;
}

body.dark-only .editor-toolbar.fullscreen,
body.dark-only .editor-preview-side {
  background: #111727;
  border: 1px solid #98a6ad;
}

body.dark-only .u-step {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .u-step.active,
body.dark-only .u-step.current {
  background: #24695c;
  color: #fff;
}

body.dark-only .u-step-title,
body.dark-only .u-pearl-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .u-step-number {
  background-color: #111727;
}

body.dark-only .u-pearl:before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl-number,
body.dark-only .u-pearl-icon {
  background: #202938;
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl.disabled .u-pearl-icon,
body.dark-only .u-pearl.disabled .u-pearl-number {
  background: rgba(36, 105, 92, 0.2);
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .u-pearl.disabled:after {
  background-color: #334053;
}

body.dark-only .u-pearl.error:after {
  background-color: #202938;
}

body.dark-only .note-editor.note-frame {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .swal-modal {
  background-color: #111727;
}

body.dark-only .swal-modal .swal-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .swal-modal .swal-text {
  color: #98a6ad;
}

body.dark-only .swal-modal .swal-content__input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .nav-tabs {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-link.active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-link:hover,
body.dark-only .nav-tabs .nav-link:focus {
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs .nav-item.show .nav-link {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.active,
body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.focus,
body.dark-only .nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #111727 rgba(36, 105, 92, 0.2) rgba(36, 105, 92, 0.2);
}

body.dark-only .nav-success .nav-link,
body.dark-only .nav-pills.nav-success .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .nav-pills.nav-success .nav-link .border-tab.nav-left .nav-link,
body.dark-only .border-tab.nav-right .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .nav-pills.nav-success
  .nav-link
  .border-tab.nav-left
  .nav-link.active,
body.dark-only .border-tab.nav-right .nav-link.active {
  color: #24695c;
}

body.dark-only
  .nav-pills.nav-success
  .nav-link
  .border-tab.nav-left
  .show
  > .nav-link,
body.dark-only .border-tab.nav-right .show > .nav-link {
  color: #24695c;
}

body.dark-only .border-tab.nav-left.nav-secondary .nav-link.active,
body.dark-only .border-tab.nav-left.nav-secondary .nav-link:focus,
body.dark-only .border-tab.nav-left.nav-secondary .nav-link:hover {
  border-left-color: #ba895d;
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #ba895d;
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-left.nav-secondary .nav-item.show {
  color: #ba895d !important;
  border-left-color: #ba895d;
}

body.dark-only .border-tab.nav-left.nav-success .nav-link.active,
body.dark-only .border-tab.nav-left.nav-success .nav-link:focus,
body.dark-only .border-tab.nav-left.nav-success .nav-link:hover {
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #1b4c43;
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-left.nav-success .nav-item.show {
  color: #1b4c43 !important;
  border-left-color: #1b4c43;
}

body.dark-only .border-tab.nav-right.nav-info .nav-link.active,
body.dark-only .border-tab.nav-right.nav-info .nav-link:focus,
body.dark-only .border-tab.nav-right.nav-info .nav-link:hover {
  color: #717171 !important;
}

body.dark-only .border-tab.nav-right.nav-info .show > .nav-link {
  border-left-color: #717171;
  color: #717171 !important;
}

body.dark-only .border-tab.nav-right.nav-info .nav-item.show {
  color: #717171 !important;
  border-left-color: #717171;
}

body.dark-only .border-tab.nav-secondary .nav-link.active,
body.dark-only .border-tab.nav-secondary .nav-link:focus,
body.dark-only .border-tab.nav-secondary .nav-link:hover {
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-secondary .nav-item.show {
  color: #ba895d !important;
}

body.dark-only .border-tab.nav-success .nav-link.active,
body.dark-only .border-tab.nav-success .nav-link:focus,
body.dark-only .border-tab.nav-success .nav-link:hover {
  color: #1b4c43 !important;
}

body.dark-only .border-tab.nav-success .nav-item.show {
  color: #1b4c43 !important;
}

body.dark-only .dropdown-divider {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom {
  background-color: #111727;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(36, 105, 92, 0.2);
  box-shadow: 0px 0px 1px 1px rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom .icon-title {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .icon-hover-bottom span {
  color: #98a6ad;
}

body.dark-only .icon-hover-bottom .form-group input {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .icon-hover-bottom .icon-first i {
  color: #98a6ad;
}

body.dark-only code {
  background-color: #202938;
  border-radius: 2px;
}

body.dark-only #cd-timeline::before {
  background: rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny::before {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny .timeliny-dot {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .timeliny .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock.active
  .timeliny-dot:after {
  background-color: #202938;
  border-color: rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover {
  background-color: #24695c;
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock:not(.inactive):not(.active)
  .timeliny-dot:hover::before {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  .timeliny
  .timeliny-timeline
  .timeliny-timeblock
  .timeliny-dot:hover::after {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .todo .todo-list-wrapper #todo-list li .task-container {
  background: #111727;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li
  .task-container
  .task-label {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .todo .todo-list-wrapper #todo-list li .task-container:hover h4 {
  color: #fff;
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li
  .task-container
  .task-action-btn
  .action-box:hover:not(.active) {
  background: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .task-label {
  color: #24695c;
}

body.dark-only
  .todo
  .todo-list-wrapper
  #todo-list
  li.completed
  .task-container
  .complete-btn:hover {
  border: 1px solid #1b4c43 !important;
}

body.dark-only
  .todo
  .todo-list-wrapper
  .todo-list-footer
  .new-task-wrapper
  textarea {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .introjs-helperLayer {
  background-color: rgba(54, 64, 74, 0.9);
  border: rgba(255, 255, 255, 0.5);
}

body.dark-only .jstree-default .jstree-node,
body.dark-only .jstree-default .jstree-icon {
  color: #455869 !important;
}

body.dark-only .jstree-default .jstree-anchor {
  color: #98a6ad;
}

body.dark-only .jstree-default .jstree-clicked {
  color: #fff;
  background-color: transparent;
}

body.dark-only .jstree-default .jstree-hovered {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only span.twitter-typeahead .tt-menu {
  background-color: #202938;
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only span.twitter-typeahead .tt-suggestion {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only span.twitter-typeahead .tt-suggestion:hover,
body.dark-only span.twitter-typeahead .tt-suggestion:focus {
  background-color: #111727;
}

body.dark-only .typography small {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .blockquote-footer {
  color: #98a6ad;
}

body.dark-only .code-box-copy pre {
  background-color: #202938;
}

body.dark-only .code-box-copy pre code {
  background-color: #202938;
}

body.dark-only .code-box-copy pre[class*="language-"] {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .code-box-copy .code-box-copy__btn {
  background-color: #202938;
  border: 1px solid rgba(36, 105, 92, 0.2);
  color: #fff;
}

body.dark-only .code-box-copy code[class*="language-"],
body.dark-only .code-box-copy pre[class*="language-"] {
  text-shadow: 0px 1px #000;
}

body.dark-only .code-box-copy code[class*="language-"] ::-moz-selection,
body.dark-only .code-box-copy pre[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #111727;
}

body.dark-only .code-box-copy code[class*="language-"] ::selection,
body.dark-only .code-box-copy pre[class*="language-"] ::selection {
  text-shadow: none;
  background: #111727;
}

body.dark-only table.fixedHeader-floating {
  background-color: #202938;
}

body.dark-only .dt-button-info {
  background-color: #111727;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .dt-button-info h2 {
  background-color: #111727;
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only #keytable_wrapper .dataTables_paginate,
body.dark-only #column-selector_wrapper .dataTables_paginate,
body.dark-only #auto-fill_wrapper .dataTables_paginate,
body.dark-only #custom-button_wrapper .dataTables_paginate,
body.dark-only #class-button_wrapper .dataTables_paginate,
body.dark-only #keyboard-btn_wrapper .dataTables_paginate,
body.dark-only #multilevel-btn_wrapper .dataTables_paginate,
body.dark-only #pagelength-btn_wrapper .dataTables_paginate,
body.dark-only #basic-colreorder_wrapper .dataTables_paginate,
body.dark-only #state-saving_wrapper .dataTables_paginate,
body.dark-only #real-time_wrapper .dataTables_paginate,
body.dark-only #basic-fixed-header_wrapper .dataTables_paginate,
body.dark-only #fixed-header-footer_wrapper .dataTables_paginate,
body.dark-only #export-button_wrapper .dataTables_paginate,
body.dark-only #excel-cust-bolder_wrapper .dataTables_paginate,
body.dark-only #cust-json_wrapper .dataTables_paginate,
body.dark-only #basic-key-table_wrapper .dataTables_paginate,
body.dark-only #focus-cell_wrapper .dataTables_paginate,
body.dark-only #responsive_wrapper .dataTables_paginate,
body.dark-only #new-cons_wrapper .dataTables_paginate,
body.dark-only #show-hidden-row_wrapper .dataTables_paginate,
body.dark-only #basic-row-reorder_wrapper .dataTables_paginate,
body.dark-only #full-row_wrapper .dataTables_paginate,
body.dark-only #rest-column_wrapper .dataTables_paginate {
  border: none;
}

body.dark-only #example-style-8_wrapper table.dataTable.stripe tbody tr.odd,
body.dark-only #example-style-8_wrapper table.dataTable.display tbody tr.odd {
  background-color: rgba(36, 105, 92, 0.2);
}

body.dark-only .error-wrapper {
  background-color: rgba(54, 64, 74, 0.8);
}

body.dark-only .error-wrapper .sub-content {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .b-light {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
}

body.dark-only .modal-content {
  background-color: #111727;
}

body.dark-only .modal-content .modal-header {
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .modal-content .ui-front .form-control {
  background-color: #111727;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .stepwizard .stepwizard-row:before {
  background-color: #98a6ad;
}

body.dark-only .modal .theme-close {
  background-color: #111727 !important;
  -webkit-filter: invert(0);
  filter: invert(0);
}

body.dark-only .token.atrule,
body.dark-only .token.attr-value,
body.dark-only .token.keyword {
  color: #717171;
}

body.dark-only .token.boolean,
body.dark-only .token.constant,
body.dark-only .token.deleted,
body.dark-only .token.number,
body.dark-only .token.property,
body.dark-only .token.symbol,
body.dark-only .token.tag {
  color: #d22d3d;
}

body.dark-only .loader-box.card-loader {
  background-color: #111727;
}

body.dark-only .my-gallery.gallery-with-description img {
  border: 1px solid rgba(36, 105, 92, 0.2) !important;
  border-bottom: none !important;
}

body.dark-only .alert-primary.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-secondary.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-success.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-danger.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-warning.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-info.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-light.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-dark.inverse {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .alert-theme span + span + span {
  border-left: 5px solid #24695c;
  background-color: #202938;
  border-radius: 4px;
}

body.dark-only .alert-theme i {
  color: #fff;
  padding: 20px;
  margin-right: 20px !important;
}

body.dark-only .user-card .user-deatils h6 {
  color: #98a6ad;
}

body.dark-only .user-card .card-footer > div:first-child {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .user-card .card-footer .user-footer h6 {
  color: #98a6ad;
}

body.dark-only .user-card .card-footer .user-footer svg path,
body.dark-only .user-card .card-footer .user-footer svg rect {
  color: #98a6ad;
}

body.dark-only .dashboard-chat .chat .media .media-body .message-main p {
  background-color: #202938;
}

body.dark-only
  .dashboard-chat
  .chat
  .media
  .media-body
  .message-main.smiley-bg {
  background-color: #202938;
}

body.dark-only .dashboard-chat .chat .right-side-chat .media-body p:before {
  border-left: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .dashboard-chat .chat .left-side-chat .media-body p:before {
  border-right: 7px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .selling-update {
  border: 2px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .bitcoin-accordion .card .media-accordion .media:nth-child(2) {
  background-color: #1c222b;
}

body.dark-only .bitcoin-accordion .card .media-accordion .media .media-body p {
  color: #98a6ad;
}

body.dark-only .bitcoin-accordion .card .media-accordion .media + .media {
  border-top: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .server-card-bg {
  background-color: #181f27 !important;
}

body.dark-only .server-widgets .bottom-server h5 .second-color {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .select2-drpdwn-project .form-control {
  background-color: #111727 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

body.dark-only .tabs-project .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .current-progress table tr td,
body.dark-only .current-progress table tr th,
body.dark-only .order-history table tr td,
body.dark-only .order-history table tr th {
  border-top: none !important;
}

body.dark-only .current-progress .title-orders,
body.dark-only .order-history .title-orders {
  background-color: #202938;
}

body.dark-only .navigation-option ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .navigation-option ul li:hover {
  background-color: #202938;
}

body.dark-only .product-box .product-details h6 {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .product-box .product-details span {
  color: #98a6ad;
}

body.dark-only .product-box .product-img .product-hover ul li {
  background-color: #111727;
}

body.dark-only .product-box .product-img .product-hover ul li:hover {
  background-color: #000;
  color: #fff;
}

body.dark-only .browse .browse-articles ul li a {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .browse .browse-articles ul li:hover {
  background-color: #202938;
}

body.dark-only .radio-primary input[type="radio"] + label::before {
  border-color: #24695c !important;
}

body.dark-only .radio-primary input[type="radio"] + label::after {
  background-color: #24695c;
}

body.dark-only .radio-primary input[type="radio"]:checked + label::before {
  border-color: #24695c !important;
}

body.dark-only .radio-primary input[type="radio"]:checked + label::after {
  background-color: #24695c;
}

body.dark-only .radio-secondary input[type="radio"] + label::before {
  border-color: #ba895d !important;
}

body.dark-only .radio-secondary input[type="radio"] + label::after {
  background-color: #ba895d;
}

body.dark-only .radio-secondary input[type="radio"]:checked + label::before {
  border-color: #ba895d !important;
}

body.dark-only .radio-secondary input[type="radio"]:checked + label::after {
  background-color: #ba895d;
}

body.dark-only .radio-success input[type="radio"] + label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .radio-success input[type="radio"] + label::after {
  background-color: #1b4c43;
}

body.dark-only .radio-success input[type="radio"]:checked + label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .radio-success input[type="radio"]:checked + label::after {
  background-color: #1b4c43;
}

body.dark-only .radio-danger input[type="radio"] + label::before {
  border-color: #d22d3d !important;
}

body.dark-only .radio-danger input[type="radio"] + label::after {
  background-color: #d22d3d;
}

body.dark-only .radio-danger input[type="radio"]:checked + label::before {
  border-color: #d22d3d !important;
}

body.dark-only .radio-danger input[type="radio"]:checked + label::after {
  background-color: #d22d3d;
}

body.dark-only .radio-info input[type="radio"] + label::before {
  border-color: #717171 !important;
}

body.dark-only .radio-info input[type="radio"] + label::after {
  background-color: #717171;
}

body.dark-only .radio-info input[type="radio"]:checked + label::before {
  border-color: #717171 !important;
}

body.dark-only .radio-info input[type="radio"]:checked + label::after {
  background-color: #717171;
}

body.dark-only .radio-light input[type="radio"] + label::before {
  border-color: #e6edef !important;
}

body.dark-only .radio-light input[type="radio"] + label::after {
  background-color: #e6edef;
}

body.dark-only .radio-light input[type="radio"]:checked + label::before {
  border-color: #e6edef !important;
}

body.dark-only .radio-light input[type="radio"]:checked + label::after {
  background-color: #e6edef;
}

body.dark-only .radio-dark input[type="radio"] + label::before {
  border-color: #2c323f !important;
}

body.dark-only .radio-dark input[type="radio"] + label::after {
  background-color: #2c323f;
}

body.dark-only .radio-dark input[type="radio"]:checked + label::before {
  border-color: #2c323f !important;
}

body.dark-only .radio-dark input[type="radio"]:checked + label::after {
  background-color: #2c323f;
}

body.dark-only .radio-warning input[type="radio"] + label::before {
  border-color: #e2c636 !important;
}

body.dark-only .radio-warning input[type="radio"] + label::after {
  background-color: #e2c636;
}

body.dark-only .radio-warning input[type="radio"]:checked + label::before {
  border-color: #e2c636 !important;
}

body.dark-only .radio-warning input[type="radio"]:checked + label::after {
  background-color: #e2c636;
}

body.dark-only .checkbox-primary label::before {
  border-color: #24695c !important;
}

body.dark-only .checkbox-secondary label::before {
  border-color: #ba895d !important;
}

body.dark-only .checkbox-success label::before {
  border-color: #1b4c43 !important;
}

body.dark-only .checkbox-danger label::before {
  border-color: #d22d3d !important;
}

body.dark-only .checkbox-info label::before {
  border-color: #717171 !important;
}

body.dark-only .checkbox-light label::before {
  border-color: #e6edef !important;
}

body.dark-only .checkbox-dark label::before {
  border-color: #2c323f !important;
}

body.dark-only .checkbox-warning label::before {
  border-color: #e2c636 !important;
}

body.dark-only .checkbox-solid-primary label:before {
  background-color: #24695c !important;
  border-color: #24695c !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::before {
  background-color: #24695c !important;
  border-color: #24695c !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-primary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-secondary label:before {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::before {
  background-color: #ba895d !important;
  border-color: #ba895d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-secondary
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-success label:before {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::before {
  background-color: #1b4c43 !important;
  border-color: #1b4c43 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-success
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-danger label:before {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::before {
  background-color: #d22d3d !important;
  border-color: #d22d3d !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-danger
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-info label:before {
  background-color: #717171 !important;
  border-color: #717171 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-info
  input[type="checkbox"]:checked
  + label::before {
  background-color: #717171 !important;
  border-color: #717171 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-info
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-light label:before {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-light
  input[type="checkbox"]:checked
  + label::before {
  background-color: #e6edef !important;
  border-color: #e6edef !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-light
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-dark label:before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-dark
  input[type="checkbox"]:checked
  + label::before {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-dark
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .checkbox-solid-warning label:before {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::before {
  background-color: #e2c636 !important;
  border-color: #e2c636 !important;
  color: #fff !important;
}

body.dark-only
  .checkbox-solid-warning
  input[type="checkbox"]:checked
  + label::after {
  color: #fff !important;
}

body.dark-only .bootstrap-datetimepicker-widget ul li {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .bootstrap-datetimepicker-widget tr th:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr th span:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td span:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr th.prev:hover,
body.dark-only .bootstrap-datetimepicker-widget tr th.next:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.prev:hover,
body.dark-only .bootstrap-datetimepicker-widget tr td.next:hover {
  background-color: #111727;
}

body.dark-only .bootstrap-datetimepicker-widget tr:first-child th:hover {
  background-color: #111727;
}

body.dark-only .btn-transparent {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-next-button
  .fc-icon-right-single-arrow:after,
body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-next-button
  .fc-icon-right-single-arrow:after {
  color: #98a6ad;
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-prev-button
  .fc-icon-left-single-arrow:after,
body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-right
  .fc-prev-button
  .fc-icon-left-single-arrow:after {
  color: #98a6ad;
}

body.dark-only
  #cal-basic
  .fc-toolbar
  .fc-left
  .fc-button-group
  .fc-month-button {
  color: #fff !important;
}

body.dark-only .fc-button-group .fc-basicWeek-button,
body.dark-only .fc-button-group .fc-basicDay-button,
body.dark-only .fc-button-group .fc-agendaWeek-button,
body.dark-only .fc-button-group .fc-agendaDay-button {
  color: #000 !important;
}

body.dark-only .fc-button-group .fc-month-button {
  color: #fff !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light {
  color: #000 !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button {
  color: #fff !important;
}

body.dark-only
  #cal-agenda-view
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-agendaWeek-button:hover {
  color: #000 !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only #cal-bg-events .fc-toolbar .fc-right .fc-button-group .btn-light,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-basicWeek-button,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .btn-light {
  color: #000 !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button {
  color: #fff !important;
}

body.dark-only
  .basic-calendar
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
body.dark-only
  #cal-bg-events
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover,
body.dark-only
  #cal-event-colors
  .fc-toolbar
  .fc-right
  .fc-button-group
  .fc-month-button:hover {
  color: #000 !important;
}

body.dark-only .categories ul li a {
  color: #98a6ad;
}

body.dark-only .main .langChoice {
  background: #202938;
  color: #fff;
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .socialprofile .likes-profile h5 span {
  color: rgba(255, 255, 255, 0.7);
}

body.dark-only .socialprofile span {
  color: #98a6ad;
}

body.dark-only .social-status .media .media-body span + span {
  color: #98a6ad;
}

body.dark-only .social-status .media .media-body p,
body.dark-only .social-status .media .media-body .light-span {
  color: #98a6ad;
}

body.dark-only .filter-cards-view p,
body.dark-only .timeline-content p {
  color: #98a6ad;
}

body.dark-only .filter-cards-view .comment-number i,
body.dark-only .timeline-content .comment-number i {
  color: #98a6ad;
}

body.dark-only .filter-cards-view .comments-box .input-group .form-control,
body.dark-only .timeline-content .comments-box .input-group .form-control {
  border: none !important;
}

body.dark-only
  .filter-cards-view
  .comments-box
  .input-group
  .input-group-append,
body.dark-only
  .timeline-content
  .comments-box
  .input-group
  .input-group-append {
  background-color: #202938;
}

body.dark-only .social-chat .media-body {
  border: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only .social-chat .media-body:after {
  border-right: 7px solid #111727;
}

body.dark-only .social-chat span span {
  color: #98a6ad;
}

body.dark-only .details-about .your-details p {
  color: #98a6ad;
}

body.dark-only .activity-log .my-activity p {
  color: #98a6ad;
}

body.dark-only.rtl .page-main-header .main-header-right .nav-right > ul > li {
  border-right: none;
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(2) {
  border-right: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(5) {
  border-right: none;
  border-left: 1px solid rgba(36, 105, 92, 0.2);
}

body.dark-only.rtl
  .page-main-header
  .main-header-right
  .nav-right
  > ul
  > li:nth-child(4) {
  border-left: none;
}

body.dark-only .bookmark ul li a {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 767px) {
  body.dark-only .chat-box .people-list ul li {
    border-color: rgba(36, 105, 92, 0.2);
  }
}

body.dark-only .footer .ftr-contact a {
  color: #b2b3b5;
}

body.dark-only .dashboard-ico {
  background-image: url(../images/svg/dark/attendence.svg);
}

/* Subject-1 Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-1 {
  background-color: #f4e5e9;
  border: 2px solid #f4e5e9;
}
/* Subject-1 End*/
/* Subject-2 Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-2 {
  background-color: #d4deff;
  border: 2px solid #d4deff;
}
/* Subject-2 End*/
/* Subject-3 Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-3 {
  background-color: #e9e5fe;
  border: 2px solid #e9e5fe;
}
/* Subject-3 End*/
/* Subject-1 Hover Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-1:hover {
  border: 2px solid #ffbeaf;
}
/* Subject-1 Hover End*/
/* Subject-2 Hover Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-2:hover {
  border: 2px solid #a7beff;
}
/* Subject-2 Hover End*/
/* Subject-3 Hover Start*/
body.dark-only .subject-sec .main .scroll .scroll-container.sub-3:hover {
  border: 2px solid #e2ccff;
}
/* Subject-3 Hover End*/
body.dark-only .mark-all-sec .option {
  background: #202a3b;
}
body.dark-only .attend-sec .search-list .nav-item {
  background-color: rgb(32 42 59);
  height: 45px;
}
body.dark-only .attend-sec .nav-tabs {
  border-bottom: none;
}
body.dark-only .alphabets {
  color: #64760e;
  background-color: #adc926;
}
body.dark-only .students-sort {
  border-bottom: 1px solid #ffffff21;
}
body.dark-only .sort-alph {
  color: #ffffff;
  background-color: #111727;
}
body.dark-only .responsive-table .table-row {
  background-color: #111727;
  border-bottom: 1px solid #ffffff00;
}
body.dark-only .t-switch label.absent {
  color: #f16e6e;
}
body.dark-only .t-switch label.present {
  color: #629d35;
}
body.dark-only .switch-candy a {
  background-image: -webkit-linear-gradient(180deg, #73276b 0%, #360b36 100%);
  background-image: linear-gradient(180deg, #73276b 0%, #360b36 100%);
}
body.dark-only .std-container .switch-toggle .disabled svg:first-child,
body.dark-only .std-container .switch-toggle .disabled svg:last-child {
  stroke: #111727;
}
body.dark-only .switch-field label {
  background-color: #1f2939;
  color: rgb(205 205 205 / 60%);
}

body.dark-only .assignment-sec .card.sub-1 {
  background-color: #fdeeee !important;
  border-left: 5px solid #f1512a !important;
}
body.dark-only .assignment-sec .card.sub-1 .card-footer h5 {
  color: #f1512a !important;
  background-color: #fecfc4 !important;
}
body.dark-only .assignment-sec .card.sub-2 {
  background-color: #d4deff !important;
  border-left: 5px solid #1235a7 !important;
}
body.dark-only .assignment-sec .card.sub-2 .card-footer h5 {
  color: #1235a7 !important;
  background-color: #a7beff !important;
}
body.dark-only .assignment-sec .card.sub-3 {
  background-color: #e9e5fe !important;
  border-left: 5px solid #411f87 !important;
}
body.dark-only .assignment-sec .card.sub-3 .card-footer h5 {
  color: #411f87;
  background-color: #e2ccff !important;
}
body.dark-only .assignment-sec .card.sub-4 {
  background-color: #eafdf6 !important;
  border-left: 5px solid #53c7a1 !important;
}
body.dark-only .assignment-sec .card.sub-4 .card-footer h5 {
  color: #3aae88 !important;
  background-color: #c3fae8 !important;
}
body.dark-only .assignment-sec .card.sub-5 {
  background-color: #fffbe1 !important;
  border-left: 5px solid #f6de42 !important;
}
body.dark-only .assignment-sec .card.sub-5 .card-footer h5 {
  color: #cab31e !important;
  background-color: #fff5b2 !important;
}
body.dark-only .assignment-sec .card.sub-6 {
  background-color: #fff9f1 !important;
  border-left: 5px solid #bd6f0c !important;
}
body.dark-only .assignment-sec .card.sub-6 .card-footer h5 {
  color: #bd6f0c !important;
  background-color: #ffd8ce !important;
}

body.dark-only .assignment-sec .card.stack-card .card-footer {
  background-color: #11172700 !important;
  border-top: 1px solid rgb(36 105 92 / 0%) !important;
}
body.dark-only .assignment-sec .card.stack-card .card-header h6 {
  color: #051b26 !important;
}
body.dark-only .view-assignment .student-image .student-name {
  color: #adc926;
}
body.dark-only .details-banner h3 {
  color: #ffffff;
}
body.dark-only .file-content .files .file-box .file-top {
  color: #fff;
  background-image: linear-gradient(180deg, #1f2939 0%, #263348 100%);
}
body.dark-only .assignment-details .knowledgebase-search .details-banner h3 {
  color: #000000;
}
body.dark-only .assignment-status .student:hover {
  color: #fff;
  background-color: #1f2939;
}

body.dark-only .rating-card .card .card-body {
  padding: 30px;
  background-color: #1f2939;
}
body.dark-only .review-card .review-title {
  color: #ffffff;
}
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a {
  background-color: rgb(223 233 176 / 35%);
}
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a:hover.br-selected,
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-bars-1to10
  .br-widget
  a:hover {
  background-color: #93ad17;
}
body.dark-only
  .compact-wrapper
  .page-body-wrapper
  .page-body
  .card
  .br-theme-fontawesome-stars
  .br-widget
  a:after {
  color: rgb(98 108 98);
}
body.dark-only .final-ass-fback .inputGroup label {
  color: #ffffff;
  background-color: #2d384a;
}
body.dark-only .assessment-details .banner-img {
  background-image: url(../images/assessments/bg_1_dark.svg);
  background-size: cover;
  background-position: center center;
  display: block;
}
body.dark-only .assessment-details .desc h5 {
  border-bottom: 1px solid #7070702b;
  padding-bottom: 8px;
  z-index: 99;
}
body.dark-only .assessment-details .modal-footer {
  border-top: 1px solid #7070702b;
}
body.dark-only .compact-wrapper .page-body-wrapper .page-body .rank-list .card {
  background-color: #1a1746;
  -webkit-box-shadow: 1px 1px 2px 1px rgb(29 28 28 / 8%);
  box-shadow: 1px 1px 2px 1px rgb(29 28 28 / 8%);
  border: 1px solid #1a1746;
}
body.dark-only .add-ques .stack-card .footer-blk-4 .info {
  color: #ffffff;
}
body.dark-only .assessment-sec .inputGroup label {
  color: #ffffff;
  background-color: #1f2939;
  border: 1px solid #1f2939;
}
body.dark-only .assessment-sec .inputGroup {
  background-color: #111727;
}
body.dark-only
  .form-bookmark
  .form-group
  .select2-container
  .select2-selection--single {
  border-color: #111727 !important;
}
body.dark-only .create-ques .form-bookmark .add-qn a {
  color: #ffffff !important;
  background-color: #1f2939;
  border: 1px solid #1f2939;
}
body.dark-only .create-ques .form-bookmark .add-qn a:hover {
  color: #ffffff !important;
  background-color: #3b4554;
  border: 1px solid #3b4554;
}
body.dark-only .tt-box .tt-row .tt-col .tt-title .tt-title-inner {
  background: #111727;
}
body.dark-only .tt-box .tt-row .tt-col .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#dadada, #dadada);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only .tt-box .tt-row .tt-col.monday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#feb68d, #feb68d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only
  .tt-box
  .tt-row
  .tt-col.tuesday
  .tt-title
  .tt-title-inner
  .title {
  background: -webkit-linear-gradient(#9cb0ff, #9cb0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only
  .tt-box
  .tt-row
  .tt-col.wednesday
  .tt-title
  .tt-title-inner
  .title {
  background: -webkit-linear-gradient(#d5c2ff, #d5c2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only
  .tt-box
  .tt-row
  .tt-col.thursday
  .tt-title
  .tt-title-inner
  .title {
  background: -webkit-linear-gradient(#8fddc0, #8fddc0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only .tt-box .tt-row .tt-col.friday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#f2e592, #f2e592);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only
  .tt-box
  .tt-row
  .tt-col.saturday
  .tt-title
  .tt-title-inner
  .title {
  background: -webkit-linear-gradient(#b5edfd, #b5edfd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only .tt-box .tt-row .tt-col.sunday .tt-title .tt-title-inner .title {
  background: -webkit-linear-gradient(#e3e3e3, #e3e3e3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body.dark-only .tt-box .tt-row .tt-col .tt-desc.tt-desc-inner {
  background-color: #1f2939;
}
body.dark-only .tt-box .tt-row .tt-col .tt-desc.tt-desc-inner .title {
  color: #dd8edd;
}
body.dark-only .tt-box .tt-row .tt-col .tt-desc.tt-desc-inner .duration .start,
body.dark-only .tt-box .tt-row .tt-col .tt-desc.tt-desc-inner .duration .end {
  color: #000;
}
body.dark-only .timetable .nav-pills .nav-link {
  color: #fff;
  background-color: #4c5564;
}
body.dark-only .nav-pills .nav-link.active,
body.dark-only .timetable .nav-pills .show > .nav-link {
  background-color: #78266a;
}
body.dark-only .timetable .nav-pills .nav-link:hover {
  color: #fff;
  background-color: #78266a;
}
