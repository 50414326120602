.shbg {
  background: linear-gradient(180deg, rgb(119, 37, 105), rgb(82, 34, 74));
}
.shfontfam {
  font-family: "Neusa Next Std";
}
.shgray {
  background-color: #eef2ff;
}
.shgrayclr {
  color: #857e84;
}
.shblue {
  color: #241549;
}
.shblack {
  color: #1e0a1b;
}
.form-check-input:checked {
  background-color: #772569;
  color: #772569;
}
.form-check-input:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgb(52, 2, 44);
}
.form-check-input:checked + .form-check-label {
  color: #772569; /* Change to the color you want */
}
.btnshpro {
  background-color: #772569;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 40px;

  border-radius: 5px;
  margin-top: 20px;
}
/* .bottom-modal-dialog {
  bottom: 0;
  top: 50%;
  transform: translate(0, 100%);
  transition: transform 0.3s ease-out;
}

.modal.show .bottom-modal-dialog {
  transform: translate(0, 0);
} */
.search-container {
  width: 200px;
}

.input-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right spacing as needed */
  transform: translateY(-50%);
}
/* .custom-tab-header {
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px; 
}
.custom-tab-header {
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  border-width: 10;
  border-color: red;
  color: #fff;
}

.active-tab-header {
  border-width: 10;
  border-color: red;
  color: #772569; 
}
.left-align {
  text-align: left;
} */
body.dark-only .AddBalance {
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.AddBalance {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
