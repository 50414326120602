.wallet-icon {
  width: 90px;
  height: 20px;
}
.SubLeave {
  width: 90px;
  height: 20px;
}
.spanstyle {
  margin-left: 5px;
}
