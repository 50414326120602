.table-responsive .custom-table input {
    width: 50px; /* Adjust the width as needed */
  }

  .modal {
    display: none; /* Hide the modal by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    background-color: white;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 10px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    margin-right: 130px;
   
  }
  
  .close {
    color: black;
    float: right;
    font-size: 35px;
 
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  


  .modal .alert-modal p{
    color: black;
    margin-top: 15px;
    font-size: 20px;
  }
  

  .grade_div{
    margin-top: 20px;
    
  }


  
 
  
