.MyDiary {
  font-family: "Poppins";
}

.MyDiary .announcement-top {
  border-bottom: 1px solid #e6edef;
  margin-bottom: 8px;
}

.MyDiary .announcement-top label {
  margin-bottom: 0;
}

.MyDiary .announcement-top img {
  cursor: pointer;
}

.MyDiary .diary-date {
}

.MyDiary .diary-date.border-top {
  border-top: 1px solid #e6edef;
  padding-top: 20px;
}

.MyDiary .diary-date p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #051b26;
  margin-bottom: 20px;
}

.MyDiary .program-card .pc-details {
  padding: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
}

.MyDiary .program-card .pc-details .diary-title p {
  font-weight: 600;
  font-size: 22px;
  line-height: 23px;
  color: #051b26;
  margin-bottom: 5px;
}

.MyDiary .program-card .pc-details .diary-title p.timing {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #772569;
}

.MyDiary .program-card .pc-details .diary-title p.timing img {
}

.MyDiary .program-card .pc-details .diary-sub-wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex: 1;
}

.MyDiary .program-card .pc-details .diary-sub-wrapper .subject {
  background: #ffd8ce;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ef5934;
  border-radius: 5px;
  padding: 2px 10px;
  border: none;
  margin-right: 5px;
}

.MyDiary .program-card .pc-details .diary-sub-wrapper .view-note {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #051b26;
  background: #d0ec49;
  border-radius: 5px;
  border: none;
  padding: 4px 20px;
}

.MyDiary .program-card .pc-details .diary-sub-wrapper button:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

/* Note View Styling Start */

.Note_view {
  font-family: "Poppins";
}

.Note_view .main-section {
  margin: 0 !important;
  border-radius: 10px 10px 0 0;
}

.Note_view .announcement-top {
  border-bottom: 1px solid #e6edef;
}

.Note_view .note-view-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  font-family: "Poppins";
}

.Note_view .class-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Note_view .class-text-wrapper .ct-title {
  margin: 10px 0 20px;
}

.Note_view .class-text-wrapper .ct-title p.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #772569;
  margin-bottom: 0;
}

.Note_view .class-text-wrapper .ct-like-btn {
}

.Note_view .class-text-wrapper .forum-header {
  display: flex;
  margin: 20px 0;
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper {
  padding-left: 20px;
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper p {
  margin-bottom: 0;
}

.Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-main-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #051b26;
}

.Note_view
  .class-text-wrapper
  .forum-header
  .forum-title-wrapper
  p.ft-sub-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}

.Note_view .class-text-wrapper .forum-header .forum-title-wrapper p.ft-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #857e84;
}

.Note_view .test-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #939191;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6edef;
}

.Note_view .stats-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.Note_view .stats-wrap p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #857e84;
}

.Note_view .comments-wrapper {
  background: #eef2fe;
  border-radius: 10px;
  padding: 10px 20px;
}

.Note_view .comments-wrapper p.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #772569;
}

.Note_view .comments-wrapper .single-comment {
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding-bottom: 10px;
}

.com-border-bottom {
  border-bottom: 1px solid #e6edef;
}

.Note_view .comments-wrapper .single-comment .com-img-box {
  display: flex;
  align-items: flex-start;
  min-width: 32px;
  margin-right: 10px;
  padding-top: 3px;
}

.Note_view .comments-wrapper .single-comment .com-img-box img {
  width: 100%;
  border: 1px solid white;
  border-radius: 50%;
}

.Note_view .comments-wrapper .single-comment .text-wrapper {
  width: 100%;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  p.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #282739;
  margin-bottom: 0;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .time {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(40, 39, 57, 0.5);
  margin-right: 10px;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .like {
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .com-heading-wrapper
  .time-and-like
  .like
  img {
  vertical-align: baseline;
}

.Note_view .comments-wrapper .single-comment .text-wrapper .comment-text {
}

.Note_view .comments-wrapper .single-comment .text-wrapper .comment-text p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(40, 39, 57, 0.5);
}

.Note_view .comments-wrapper .single-comment .text-wrapper .reply-btn-wrapper {
  display: flex;
  cursor: pointer;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .reply-btn-wrapper
  img {
  margin-right: 5px;
}

.Note_view
  .comments-wrapper
  .single-comment
  .text-wrapper
  .reply-btn-wrapper
  span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(40, 39, 57, 0.5);
}

.Note_view .main-comment-input-wrapper .form-control {
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  font-family: "Poppins";
  padding: 0.375rem 1.5rem 0.375rem 20px;
  /* margin-top: 40px; */
}

.Note_view .main-comment-input-wrapper .form-control::placeholder {
  color: #949fa6;
}

.Note_view .main-comment-input-wrapper .form-control:focus {
  box-shadow: none;
}

.Note_view .main-comment-input-wrapper {
  padding: 30px 0;
  /* margin-top: 20px; */
  box-shadow: 0px -3px 16px rgba(0, 0, 0, 0.059);
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
}

.Note_view .main-comment-input-wrapper form div {
  position: relative;
}

.Note_view .main-comment-input-wrapper form div img {
  position: absolute;
  right: 20px;
  top: -5px;
}

/* Note View Styling End */

/* Common Filter Modal Styling Start */

.common-filter-modal {
}

.common-filter-modal .modal-content {
  border-radius: 20px;
}

.common-filter-modal .modal-header {
  background: #772569;
  border-radius: 10px;
}

.common-filter-modal .modal-header .modal-title {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding-bottom: 15px;
  border: none;
}

.common-filter-modal .modal-header .modal-title .title-wrapper {
  display: flex;
  justify-content: space-between;
}

.common-filter-modal .modal-header .modal-title .title-wrapper p {
  margin: 0;
}

.common-filter-modal .modal-body {
  padding: 11px 16px;
}

.common-filter-modal .modal-body .title {
  border-bottom: 1px solid rgba(17, 17, 17, 0.2);
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.common-filter-modal .modal-body .title p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #282739;
}

.common-filter-modal .modal-body .list-wrapper {
  height: 160px;
  overflow-y: scroll;
}

.common-filter-modal .modal-body .list-wrapper::-webkit-scrollbar {
  display: none;
}

.common-filter-modal .modal-body .list-wrapper .list-item {
  display: flex;
  margin: 9px 0;
}

.common-filter-modal .modal-body .list-wrapper .list-item img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box {
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box p {
  margin: 0;
  font-weight: 400;
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box .main {
  font-size: 14px;
  line-height: 21px;
  color: #282739;
}

.common-filter-modal .modal-body .list-wrapper .list-item .text-box .sub {
  font-size: 12px;
  line-height: 18px;
  color: rgba(40, 39, 57, 0.5);
}

.common-filter-modal .modal-body .select-wrapper {
  display: block;
  position: relative;
}

.common-filter-modal .modal-body .select-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.common-filter-modal .modal-body .select-wrapper ul li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.common-filter-modal .modal-body .select-wrapper ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.common-filter-modal .modal-body .select-wrapper ul li label {
  display: block;
  position: relative;
  padding: 10px 10px 10px 0px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #949fa6;
}

.common-filter-modal .modal-body .select-wrapper ul li:hover label {
  color: #772569;
}

.common-filter-modal .modal-body .select-wrapper ul li .check {
  display: block;
  position: absolute;
  border: 1px solid #aaaaaa;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  top: 10px;
  right: 00px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.common-filter-modal .modal-body .select-wrapper ul li:hover .check {
  border: 2px solid #949fa6;
}

.common-filter-modal .modal-body .select-wrapper ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 1px;
  left: 1px;
  margin: auto;
  transition: #772569 0.25s linear;
  -webkit-transition: #772569 0.25s linear;
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ .check {
  border: 2px solid #949fa6;
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ .check::before {
  background: linear-gradient(180deg, #b651a5 0%, #732565 100%);
}

.common-filter-modal
  .modal-body
  .select-wrapper
  input[type="radio"]:checked
  ~ label {
  color: #772569 !important;
}

.common-filter-modal .modal-body.date-picker-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.common-filter-modal .modal-dialog {
  width: 330px !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: #adc927 !important;
}

.Calendar__day.-ltr {
  min-height: 3em !important;
}

.Calendar {
  padding: 0 !important;
  border-radius: 20px !important;
}

.Calendar__weekDays {
  color: #1c1b1f !important;
  padding: 0 17px !important;
}

.Calendar__header {
  background: #772569 !important;
  border-radius: 20px;
  margin-bottom: 20px !important;
  height: 60px !important;
}

.Calendar > :not(.Calendar__footer) button {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
}

.Calendar__monthArrow {
  background-image: url("../images/cal_right_arrow.svg") !important;
  transform: rotate(-90deg);
  width: 12px !important;
  height: 12px !important;
}

.Calendar__section {
  padding: 0 17px !important;
}

.common-filter-modal .modal-footer .btn-wrapper {
  padding: 0;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1,
.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2 {
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 88px;
  height: 40px;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1:focus,
.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2:focus {
  outline: none;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-1 {
  border: 2px solid #949fa6;
  background-color: #fff;
  margin-right: 10px;
  color: #949fa6;
}

.common-filter-modal .modal-footer .btn-wrapper .calendar-btn-2 {
  border: 2px solid #adc927;
  background-color: #adc927;
  color: #ffffff;
}

.common-filter-modal .modal-footer {
  background: #fff;
  z-index: 9999999999999;
  position: inherit;
  border-radius: 20px;
  padding-top: 0;
}

/* Common Filter Modal Styling End */
