body.dark-only .cardFeed {
  background: #111727;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
.cardFeed {
  background: #eaeefd;
  border: 1px solid #e6edef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}
body.dark-only .cardFeed .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;

  font-family: "Poppins";
  margin-left: 10px;
}
.cardFeed .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #27272e;

  font-family: "Poppins";
  margin-left: 10px;
}
body.dark-only .cardFeed .card-date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-top: -10px;

  color: #ffff;
  margin-bottom: 5px;
  font-family: "Poppins";
  margin-left: 10px;
}
.cardFeed .card-date {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-top: -10px;

  color: #241549;
  margin-bottom: 5px;
  font-family: "Poppins";
  margin-left: 10px;
}
body.dark-only .Feed-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffff;

  font-family: "Poppins";
}
.Feed-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;

  font-family: "Poppins";
}
body.dark-only .Feed-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
}
.Feed-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #857e84;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
}
.margin-left {
  margin-left: 10px;
}
