/* MarkEntryTable.css */
/* Placeholder color */
s/* Customize the appearance of the dropdown */
.offlineassesment .form-control {
  border-radius: 5px; /* Rounded corners */
  border: 1px solid red; /* Border color */
  padding: 8px; /* Padding inside the dropdown */
  background-color: #78266a; /* Background color */
  color: #333; /* Text color */
}

/* Style the dropdown when it's focused */
.offlineassesment .form-control:focus {
  border-color: white; /* Border color when focused */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Focus shadow */
}

/* Style the dropdown arrow */
.form-control::after {
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Style the placeholder text */
.form-control option[disabled] {
  color: #999; /* Placeholder color */
}

.table {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-collapse: collapse;
  width: 90%;
  margin: 30px;
  border-radius: 10px;
}

.thead {
  background-color: #78266a;
  color: white;
  border-radius: 10px;
}

.tr {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.th,
.td {
  flex: 1;
  padding: 10px;
  /* border: 1px solid #ccc; */
}

.th {
  font-weight: bold;
}
.thsl {
  width: 80px;
  align-self: center;

  display: flex;
  font-weight: bold;
}
.thsn {
  width: 190px;
  align-self: center;
  display: flex;
  font-weight: bold;
}
.thm {
  width: 120px;
  align-self: center;
  display: flex;
  font-weight: bold;
}
.thI {
  width: 120px;
  align-self: center;

  display: flex;
  font-weight: bold;
}
body.dark-only .tdsl {
  width: 80px;
  align-self: center;
  color: white;

  display: flex;
}
.tdsl {
  width: 80px;
  align-self: center;

  display: flex;
}

body.dark-only .tdsn {
  width: 190px;
  align-self: center;
  color: white;
  display: flex;
}
.tdsn {
  width: 190px;
  align-self: center;

  display: flex;
}
body.dark-only .tdm {
  width: 120px;
  align-self: center;
  display: flex;
  color: white;
}
body.dark-only .tdm2 {
  width: 110px;
  align-self: center;
  display: flex;
  color: white;
}
body.dark-only .tdm1 {
  width: 180px;
  align-self: center;
  display: flex;
  color: white;
}

.edittdm {
  width: 120px;
  align-self: center;
  display: flex;
  border: 1px solid gray;
  border-radius: 10px;
}

.tdm {
  width: 120px;
  align-self: center;
  display: flex;
}

.tdm2 {
  width: 110px;
  align-self: center;
  display: flex;
}

.tdm1 {
  width: 180px;
  align-self: center;
  display: flex;
}

.tdI {
  width: 120px;
  align-self: center;

  display: flex;
}
.tableheadstyle {
  background-color: #78266a;
  color: white;
  border-radius: 10px;
  height: 50px;
}

.student-image {
  width: 50px;
  height: 50px;
}

/* Offlinemarksheet */
.offmarkcad {
  padding-left: 30px;
}
.offmarkcad .DropTxt {
  margin-top: 10px;
  margin-bottom: -0.5px;
  line-height: 30px;
}
/* ButtonStyles.css */
.view-mark-list-button {
  background-color: #78266a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;

  border-color: #78266a;
  outline: none;
  margin-top: 20px;
}
.view-mark-list-button:hover {
  background-color: white;
  color: #78266a;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  align-self: flex-end;

  border: 1px solid;
  border-color: #78266a;
  outline: none;
  margin-top: 20px;
}
.view-mark-list-button:active {
  outline: none; /* Remove outline when button is active (clicked) */
}
.view-mark-list-button:active:hover {
  outline: none; /* Remove outline when button is active (clicked) */
}
.view-mark-list-button:focus {
  outline: none;
}

.markhead {
  margin-top: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.invalid {
  border-color: red;
}
.TermHead {
  color: #772569;
  font-size: 20px;
  font-weight: 400;
  border: none;
  bottom: 0rem;
  margin-top: 5px;
  line-height: 20px;
}

/* styles.css */
.placeholder-red::placeholder {
  color: #772569;
}

.Term {
  bottom: 0rem;
  margin-top: -16px;
  background-color: white;
  width: 100px;
}
.TermHeadd {
  color: #772569;
  font-size: 20px;
  font-weight: 400;

  line-height: 20px;
}
.Dur {
  color: #772569;
  font-size: 14px;
  font-weight: 500;
  background-color: white;

  line-height: 10px;
  text-align: center;
  border-radius: 15px;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Dur :hover {
  color: white;
  font-size: 14px;
  font-weight: 500;

  line-height: 10px;
  text-align: center;
}
.inputchoose .input ::placeholder {
  color: #772569;
}
.inputchoose {
  border: 1px solid #e6edef;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
  margin-right: 20px;
}
.pl {
  padding-left: 20px;
}
.pr {
  padding-right: 20px;
}
.headoffline {
  padding: 4px 10px;
  color: black;

  margin: 0 3px;
  cursor: pointer;
}
.bordered-text-box {
  display: inline-block; /* Ensure the box only takes as much width as needed */
  border-left: 2px solid black; /* Border on the left side */
  border-right: 2px solid black; /* Border on the right side */
  padding: 10px;
}
